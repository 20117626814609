import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import logo from '../img/kmitl_logo.png';
import railogo from '../img/Railogo.png';
import './Homepage.css';
import { gapi } from 'gapi-script'
import fetch_data from '../Component/fetch-data.js';
import Navbar from './Navbar';

function App() {
  const images = [
    'https://tactless7.github.io/cv/img/icons/react_logo_2.png',
    'http://pngimg.com/uploads/mysql/mysql_PNG22.png',
    'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*TY9uBBO9leUbRtlXmQBiug.png'
  ];

  const proimages = [
    require('../img/chanaimg.jpg'),
    require('../img/jiratpro.jpg'),
    require('../img/pongimg.jpg')
  ];

  const texts = [
    "Chanapat Damrongsukniwat - 63011121",
    "Jirat Boonnumsuk - 63011160",
    "Pongsakorn Sriyanyong - 63011269"
  ];
  
  const [profile, setProfile] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [activeProImageIndex, setActiveProImageIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeProIndex, setActiveProIndex] = useState(0);

  const handleNext = (isPro) => {
    if (isPro) {
      setActiveProImageIndex((prevIndex) => (prevIndex + 1) % proimages.length);
      setActiveProIndex((prevIndex) => (prevIndex + 1) % texts.length);
    } else {
      setActiveImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      setActiveIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }
  };
  
  const handlePrev = (isPro) => {
    if (isPro) {
      setActiveProImageIndex((prevIndex) => (prevIndex - 1 + proimages.length) % proimages.length);
      setActiveProIndex((prevIndex) => (prevIndex - 1 + texts.length) % texts.length);
    } else {
      setActiveImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      setActiveIndex((prevIndex) => (prevIndex - 1 + texts.length) % texts.length);
    }
  };
  

  useEffect(() => {
    const userCookie = Cookies.get('userData');
    if (userCookie) {
      const userData = JSON.parse(atob(userCookie.split('.')[1]));
      setProfile(userData);
    }
  }, []);

  return (
    <div>
      <body>
        <div className="bannerbg">
        </div>
        <Navbar />
        <header id="header" className="card">
          <img className="logo" src={logo} alt=""></img>
          <div className="logosep">|</div>
          <img className="railogo" src={railogo} alt=""></img>
          <h1>Scheduling Make-up classes</h1>
          <div className="kmitlhead">King Mongkut's Institute of Technology Ladkrabang</div>
          {profile ? (
            <div id="log">
              <a className="logout" href="https://smc.raikmitl.com/api/logout" >Logout</a>
              <img className="profile" src={profile.picture} alt="User Profile" />
              <p className="welcome" style={{ border: '1px solid #d2691e', borderRadius: '3px', color: '#333', fontSize: '12px', marginTop: '-64px', padding: '5px', textAlign: 'center', width: '150px'}}>{profile.displayName}</p>
              <p className="email">{profile.email}</p>
            </div>
          ) : (
            <div className="login">
              <a href="https://smc.raikmitl.com/api/auth/google" className="login">Sign in with Google</a>
            </div>
          )}
        </header>
        <div className="entire">
          <main id="main">
            <div className="mainhead" style={{ textAlign: 'center'}}>
              <h2 style={{ borderBottom: '1px solid chocolate', display:'inline-block'}}>Instruction</h2>
            </div>
            <div className="dataimport">
              <h2>Data Import</h2>
              <p>
                Enable the import of class and holiday files directly onto the Class Schedule and Holiday pages.<br />
              </p>
            </div>
            <div className="alignarrow">  
              <img className="arrow" src="https://cdn-icons-png.flaticon.com/512/5093/5093064.png"></img>
            </div>      
            <div className="classgen">
              <h2>Make-Up Class Generation</h2>
              <p>
                Automate the creation of make-up classes on the Schedule page. Users can specify start and end months, as well as the degree type.
              </p>
            </div>
            <div className="alignarrow">  
              <img className="arrow" src="https://cdn-icons-png.flaticon.com/512/5093/5093064.png"></img>
            </div>  
            <div className="exportop">
              <h2>Export Options</h2>
              <p>
                Provide the ability to export schedules to PDF or Excel formats for easy sharing and archival purposes.
              </p>
            </div>
          </main>
          <aside id="sidebar2">
            <div className="mainhead2" style={{ textAlign: 'center'}}>
              <h2 style={{ borderBottom: '1px solid chocolate', display:'inline-block'}}>Welcome to our website</h2>
            </div>
            <div className="welcome">
              <h2>Introduction</h2>
              <p>
                Our application simplifies the retrieval of student and professor timetables, incorporating regular schedules alongside holidays and breaks. By compiling this data, it generates a compensation schedule for make-up classes, streamlining coordination and reducing administrative workload. With its user-friendly interface and automated features, our application enhances efficiency and ensures seamless integration of make-up classes into the academic calendar.<br />
              </p>
            </div>
            <hr className="hr" />
            <div className="purpose">
              <h2>Purpose</h2>
              <p>
                Our web app simplifies make-up class scheduling for faculty assistants, automating tasks and minimizing errors. With centralized information, automated notifications, and intuitive design, coordination with instructors and students is streamlined, optimizing efficiency in academic scheduling. By providing a comprehensive overview of student and professor timetables, including holidays and breaks.
              </p>
            </div>
          </aside>
          <aside id="sidebar" className="card">
            <div className="developer">
              <h2>Developer</h2>
              <div className="sidebar-images">
                <div className="navigation-buttons">
                  <button className="previous" onClick={() => handlePrev(true)}>←</button>
                  <button className="next" onClick={() => handleNext(true)}>→</button>
                </div>
                <img src={proimages[activeProImageIndex]} alt="Sidebar Image" />
              </div>
              <div className="text" style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>
                {texts[activeProIndex]}
              </div>
            </div>
            <div className="advisor">
              <h2>Advisor & Co-Adviser</h2>
              <ul>
                <li>Asst. Prof. Dr. Anakkapon Saenthon</li>
                <li>Ms. Atchariya Boonnetr</li>
              </ul>
            </div>
            <hr></hr>
            <h2>Implement</h2>
            <div className="sidebar-images">
              <div className="navigation-buttons">
                <button className="previous" onClick={() => handlePrev(false)}>←</button>
                <button className="next" onClick={() => handleNext(false)}>→</button>
              </div>
              <img className="imimg" src={images[activeImageIndex]} alt="Sidebar Image" />
            </div>
          </aside>
        </div>
        <div className="clr"></div>
        <footer id="footer">
          <p className="text-center">Copyright &copy;</p>
        </footer>
      </body>
    </div>
  )
}

export default App;
