  import React, { useState, useEffect } from 'react';
  import Modal from 'react-modal';
  import fetch_data from '../Component/fetch-data.js';
  import './ConfigForm.css';

  Modal.setAppElement('#root');

  function ConfigFormModal({ isOpen, onClose, selectedMakeupClass, onUpdate, hasConflict }) {
    const [makeupDate, setMakeupDate] = useState([]);
    const [minMaxTimes, setMinMaxTimes] = useState([]);
    const [conflictMessages, setConflictMessages] = useState([]);
    
    useEffect(() => {
      if (selectedMakeupClass && selectedMakeupClass.length > 0) {
        const makeupDateArr = [];
        const minMaxTimeArr = [];
        selectedMakeupClass.forEach(makeupClass => {
          const formattedDate = new Date(makeupClass.makeupDate).toISOString().split('T')[0];
          makeupDateArr.push(formattedDate || '');
          const { min, max, makeupHour } = findMinMaxTimes(makeupClass.makeupTimeSlot);
          minMaxTimeArr.push({ min, max, makeupHour });
        });
        setMakeupDate(makeupDateArr);
        setMinMaxTimes(minMaxTimeArr);
        setConflictMessages([]);
      }

    }, [selectedMakeupClass]);

    function formatTime(timeString) {
      const [hours, minutes, seconds] = timeString.split(':');
      return `${hours}:${minutes}:${seconds}`;
    }
  
    function findMinMaxTimes(times) {
      const startTimes = times.map(time => new Date(`1970-01-01T${time.startTime}Z`));
      const endTimes = times.map(time => new Date(`1970-01-01T${time.endTime}Z`));
    
      const minTime = new Date(Math.min(...startTimes));
      const maxTime = new Date(Math.max(...endTimes));
      const min = formatTime(minTime.toISOString().substr(11, 8));
      const max = formatTime(maxTime.toISOString().substr(11, 8));
    
      return {
          min: min,
          max: max,
          makeupHour: calculateMakeupHours(min, max)

      };
      }

      const handleDateChange = (index) => (e) => {
        const updatedDates = [...makeupDate];
        updatedDates[index] = e.target.value;
        setMakeupDate(updatedDates);
      };

    const handleStartTimeChange = (e, index) => {
      const selectedTime = e.target.value;
      const formattedTime = `${selectedTime}:00`;
      const endTime = calculateEndTimeFromHours(formattedTime, minMaxTimes[index].makeupHour);
    
      setMinMaxTimes(prevMinMaxTimes => {
        const updatedMinMaxTimes = [...prevMinMaxTimes];
        updatedMinMaxTimes[index] = { ...updatedMinMaxTimes[index], min: formattedTime, max: endTime };
        return updatedMinMaxTimes;
      });
    };

    const calculateEndTime = (index) => {
      return calculateEndTimeFromHours(minMaxTimes[index]?.min, minMaxTimes[index]?.makeupHour);
    };

    const calculateMakeupHours = (min, max) => {
      let totalMakeupHours = 0;
  
      const startTime = new Date(`1970-01-01T${min}Z`);
        const endTime = new Date(`1970-01-01T${max}Z`);
        const duration = (endTime - startTime) / (1000 * 60 * 60);
        totalMakeupHours += duration;
  
      return totalMakeupHours.toFixed(2);
    };

    const calculateEndTimeFromHours = (startTime, hours) => {
      const start = new Date(`1970-01-01T${startTime}`);
      const end = new Date(start.getTime() + hours * 60 * 60 * 1000);
      const formattedEndTime = end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
    
      return formattedEndTime;
    };

    const handleSubmit = (index) => (e) => {
      e.preventDefault();
      const newConflictMessages = [...conflictMessages];
      if (hasConflict(selectedMakeupClass[index].id, new Date(`${makeupDate[index]}T00:00:00.000Z`).toISOString(), minMaxTimes[index])) {
        console.error('Conflicts found. Cannot update.');
        newConflictMessages[index] = 'Conflicts found. Cannot update.';
        setConflictMessages(newConflictMessages);
        return; 
      }
      onUpdate({ id: selectedMakeupClass[index].id, makeupDate: new Date(`${makeupDate[index]}T00:00:00.000Z`).toISOString(), makeupTimeSlot: [{ startTime: minMaxTimes[index].min, endTime: minMaxTimes[index].max }] });
      onClose();
    };

    return (
      <Modal className="config-form"
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Config Selected Time"
      > <span onClick={onClose} className="close">X</span>
        <h2>Config Selected Time</h2>
        {selectedMakeupClass.map((makeupClass, index) => (
          <div key={index}>
            <p>
        Subject Name: {makeupClass.subjectName} | Subject Code: {makeupClass.subjectCode} | Section: {makeupClass.section} | Year of Study: {makeupClass.yearOfstudy}
      </p>
        <form onSubmit={handleSubmit(index)}>
        <label>Makeup Date: <input type="date" value={makeupDate[index]} onChange={handleDateChange(index)} /></label>
        <label>
          Makeup Start Time:
          <input
            type="time"
            value={minMaxTimes[index]?.min}
            onChange={(e) => handleStartTimeChange(e, index)}
          />
        </label>
        <label>
          Makeup End Time: <input type="time" value={calculateEndTime(index)} readOnly />
        </label>
          <button type="submit">Save</button>
          {conflictMessages[index] && <p className="warning-message">{conflictMessages[index]}</p>}
        </form>
        {index !== selectedMakeupClass.length - 1 && <hr />}
        </div>
        ))}
      </Modal>
    );
  }

  export default ConfigFormModal;
