import React from 'react';
import Modal from 'react-modal';
import './SecondModal.css';

Modal.setAppElement('#root');

function SecondModal({ isOpen, onClose, selectedData }) {
  // Function to format date without time
  const formatTimeWithoutSeconds = (timeString) => {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':');
    // Return only the hours and minutes part
    return `${hours}:${minutes}`;
  };

  // Function to format date in dd-mm-yyyy format
  const formatDateWithoutTime = (dateString) => {
    const dateObject = new Date(dateString);
    // Get day, month, and year
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    // Pad day and month with leading zeros if necessary
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;
    // Return date in dd-mm-yyyy format
    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  // Map each key to the desired header name
  let professorHeaderAdded = false;

  const tableHeaders = selectedData && selectedData.length > 0
      ? Object.keys(selectedData[0]).filter(key => key !== 'id' && key !== 'endTime').map(key => {
          // Map each key to the desired header name
          switch (key) {
              case 'date':
                  return 'วันที่';
              case 'subjectName':
                  return 'ชื่อวิชา';
              case 'yearOfstudy':
                  return 'ชั้นปี';
              case 'classDay':
                  return 'วันที่เรียน';
              case 'subjectCode':
                  return 'รหัสวิชา';
              case 'startTime':
                  return 'เวลาเรียน';
              case 'startDate':
                  return 'เปิดเทอม';
              case 'endDate':
                  return 'ปิดเทอม';
              case 'section':
                  return 'กลุ่ม';
              case 'type':
                  return 'ท/ป';
              case 'professors':
                  // If the professors header hasn't been added yet, add it
                  if (!professorHeaderAdded) {
                      professorHeaderAdded = true;
                      return 'อาจารย์';
                  }
                  return null; // Exclude professor data from headers
  
              // Add more cases for other keys if needed
              default:
                  return key;
          }
      }).filter(header => header !== null) // Filter out null headers
      : [];
  
  // If 'professors' is in the headers, remove 'professor1' from the data
  if (tableHeaders.includes('อาจารย์')) {
    const professorsToRemove = [];
    for (let i = 1; i <= 50; i++) {
        professorsToRemove.push(`professor${i}`);
    }
    
    selectedData.forEach(item => {
        professorsToRemove.forEach(key => {
            delete item[key];
        });
    });
}
  return (
    <Modal
      className="second-modal"
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Second Modal"
    >
      <span onClick={onClose} className="close">X</span>
      <div className="bg-secondModal">
        {/* Add content for the second modal */}
        <h2 className='Head-Modal'></h2>
        {/* Display selected data */}
        <table>
          <thead>
            <tr>
              {/* Display table headers */}
              {tableHeaders.map(header => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Display table rows */}
            {selectedData && selectedData.length > 0 && selectedData.map(item => (
              <tr key={item.id}>
                {/* Display table cells */}
                {Object.entries(item).map(([key, value]) => (
                  // Exclude 'id' and 'endTime' fields
                  key !== 'id' && key !== 'endTime' &&
                  <td key={key}>
                    {/* If value is a date, format it without time */}
                    {key === 'date' ? formatDateWithoutTime(value) : key === 'startTime' ? `${formatTimeWithoutSeconds(value)}-${formatTimeWithoutSeconds(item.endTime)}` : key === 'startDate' || key === 'endDate' ? formatDateWithoutTime(value) : value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {/* Add any additional content or components here */}
      </div>
    </Modal>
  );
}

export default SecondModal;
