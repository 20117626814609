import React, { useState, useEffect } from 'react';
import fetch_data from '../Component/fetch-data.js';
import Navbar from './Navbar';
import './ProfSummary.css';

function ProfSummary() {
  const [professorsData, setProfessorsData] = useState({});
  const [selectedProfessor, setSelectedProfessor] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [schedule, setSchedule] = useState([]);
  const [allProfessors, setAllProfessors] = useState([]);

  useEffect(() => {
    fetch_data.getClassSchedule()
      .then((response) => response.json())
      .then((classData) => {
        console.log('Classdata : ', classData);
        organizeDataByProfessors(classData);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  }, []);

  const organizeDataByProfessors = (data) => {
    const professorsMap = {};

    data.forEach((classItem) => {
      for (let i = 1; i <= 10; i++) {
        const professor = classItem[`professor${i}`];
        const month = new Date(classItem.date).getMonth(); // Extract month from date

        if (professor) {
          if (!professorsMap[professor]) {
            professorsMap[professor] = [];
          }

          professorsMap[professor].push({
            subjectName: classItem.subjectName,
            day: classItem.classDay,
            startTime: classItem.startTime,
            endTime: classItem.endTime,
            date: classItem.date
          });
        }
      }
    });

    setProfessorsData(professorsMap);
    setAllProfessors(Object.keys(professorsMap));
  };

  const selectProfessor = (selectedProfessor) => {
    setSelectedProfessor(selectedProfessor);
    filterSchedule(selectedProfessor, selectedMonth);
  };

  const selectMonth = (selectedMonth) => {
    setSelectedMonth(selectedMonth);
    filterSchedule(selectedProfessor, selectedMonth);
  };

  const filterSchedule = (professor, month) => {
    if (!professor) {
      // If no professor selected, set schedule to empty array
      setSchedule([]);
      return;
    }

    const filteredSchedule = professorsData[professor].filter((classItem) => {
      const itemMonth = new Date(classItem.date).getMonth();
      return month === '' || itemMonth === parseInt(month);
    });

    setSchedule(filteredSchedule);
  };

  return (
    <div className="backg">
      <Navbar />
      <div className="month-picker-section">
        <label htmlFor="professor">Select Professor : </label>
        <input 
          type="text"
          id="professor"
          className="professor-input"
          list="professors-list"
          value={selectedProfessor}
          onChange={(e) => selectProfessor(e.target.value)}
          placeholder="Type or select professor"
        />
        <datalist id="professors-list">
          {allProfessors.map((professor, index) => (
            <option key={index} value={professor} />
          ))}
        </datalist>
      </div>

      <div className="month-picker-section">
        <label htmlFor="month">Select Month : </label>
        <select id="month" className="month-dropdown" onChange={(e) => selectMonth(e.target.value)}>
          <option value="">All Months</option>
          <option value="0">January</option>
          <option value="1">February</option>
          <option value="2">March</option>
          <option value="3">April</option>
          <option value="4">May</option>
          <option value="5">June</option>
          <option value="6">July</option>
          <option value="7">August</option>
          <option value="8">September</option>
          <option value="9">October</option>
          <option value="10">November</option>
          <option value="11">December</option>
        </select>
      </div>

      <div className="table-container">
        <h2>Professor Schedules</h2>
        {selectedProfessor && (
          <div key={selectedProfessor}>
            <h3 style={{ textAlign: 'center' }}>{selectedProfessor}</h3>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <table className="class-table">
                <thead>
                  <tr>
                    <th>Subject Name</th>
                    <th>Day</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                  </tr>
                </thead>
                <tbody>
                  {schedule.map((classItem, index) => (
                    <tr key={index}>
                      <td>{classItem.subjectName}</td>
                      <td>{classItem.day}</td>
                      <td>{classItem.startTime}</td>
                      <td>{classItem.endTime}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfSummary;
