import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import fetch_data from '../Component/fetch-data.js';
import './ClassForm.css';

Modal.setAppElement('#root');

function ClassFormModal({ isOpen, onClose, onAddClass, editClass }) {
  const [newClass, setNewClass] = useState({
    id: null,
    academicYear: (new Date().getFullYear() + 543).toString(),
    semester: '1',
    yearOfstudy: '1',
    classDay: 'Sunday',
    subjectCode: '',
    subjectName: '',
    type: 'ป',
    section: '',
    startTime: '',
    endTime: '',
    startDate: '',
    endDate: '',
    tutorYear: '',
    professors: ['', null, null],
    excepted: '0'
  });

  const [professorsData, setProfessorsData] = useState({});
  const [suggestedNames, setSuggestedNames] = useState([]);

  useEffect(() => {
    console.log(editClass)
    fetch_data.getClassSchedule()
      .then((response) => response.json())
      .then((classData) => {
        organizeDataByProfessors(classData);
      })
      .catch((error) => {
        console.error('Error fetching class schedule data:', error);
      });

      if (editClass) {
        setNewClass(editClass);
      }
  }, [editClass]);

  useEffect(() => {
    const allProfessors = Object.keys(professorsData);
    setSuggestedNames(allProfessors);
  }, [professorsData]);

  const organizeDataByProfessors = (data) => {
    const professorsMap = {};

    data.forEach((classItem) => {
      for (let i = 1; i <= 10; i++) {
        const professor = classItem[`professor${i}`];
        const month = new Date(classItem.date).getMonth();

        if (professor) {
          if (!professorsMap[professor]) {
            professorsMap[professor] = [];
          }

          professorsMap[professor].push({
            subjectName: classItem.subjectName,
            day: classItem.classDay,
            startTime: classItem.startTime,
            endTime: classItem.endTime,
            date: classItem.date
          });
        }
      }
    });

    setProfessorsData(professorsMap);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewClass((prevClass) => ({
      ...prevClass,
      [name]: value,
    }));
  };

  const addProfessorField = () => {
    setNewClass((prevClass) => ({
      ...prevClass,
      professors: [...prevClass.professors, '']
    }));
  };

  
  const handleProfessorChange = (index, value) => {
    const professors = [...newClass.professors];
    professors[index] = value;
    setNewClass((prevClass) => ({
      ...prevClass,
      professors: professors
    }));
  };


  const removeProfessorField = (index) => {
    if (index > 2) { // Check if index is greater than 2 (Professor 3 and beyond)
      const professors = [...newClass.professors];
      professors.splice(index, 1);
      setNewClass((prevClass) => ({
        ...prevClass,
        professors: professors
      }));
    }
  };

  const generateAcademicYearOptions = () => {
    const currentYear = new Date().getFullYear() + 543;
    const options = [];
    for (let i = currentYear - 2; i <= currentYear + 2; i++) {
      options.push(<option key={i} value={i}>{i}</option>);
    }
    return options;
  };

  const handleSubmit = () => {
    onAddClass(newClass);
    setNewClass(prevState => ({
      ...prevState,
      id: null,
      academicYear: (new Date().getFullYear() + 543).toString(),
      semester: '1',
      yearOfstudy: '1',
      classDay: 'Sunday',
      subjectCode: '',
      subjectName: '',
      type: 'ป',
      section: '',
      startTime: '',
      endTime: '',
      tutorYear: '',
      professors: ['', null, null],
      excepted: '0'
    }));
    onClose();
  };

  return (
    <Modal className="class-form"
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Add Class Modal"
    >
      <span onClick={onClose} className="close">X</span>
      <h2>Add New Class</h2>
      <form>
      <label>Academic year <p className="redmark">* </p>:&nbsp;
        <select name="academicYear" value={newClass.academicYear} onChange={handleInputChange}>
          {generateAcademicYearOptions()}
        </select>
      </label><br />
        <label>Semester <p className="redmark">* </p>:&nbsp;
          <select name="semester" value={newClass.semester} onChange={handleInputChange}>
            <option value="1">1</option>
            <option value="2">2</option>
          </select>
        </label><br />
        <label>Year of Study <p className="redmark">* </p>:&nbsp;
          <select name="yearOfstudy" value={newClass.yearOfstudy} onChange={handleInputChange}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="Minor">วิชาโท</option>
            <option value="Graduate">ปริญญาโทหรือปริญญาเอก</option>
          </select>
        </label><br />
        <label>Class Day <p className="redmark">* </p>:&nbsp;
          <select name="classDay" value={newClass.classDay} onChange={handleInputChange}>
            <option value="Sunday">Sunday</option>
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
          </select>
        </label><br />
        <label>Subject code <p className="redmark">* </p>: <input type="text" name="subjectCode" value={newClass.subjectCode} onChange={handleInputChange}/></label><br />
        <label>Subject name <p className="redmark">* </p>: <input type="text" name="subjectName" value={newClass.subjectName} onChange={handleInputChange}/></label><br />
        <label>Type <p className="redmark">* </p>:&nbsp; 
        <select name="type" value={newClass.type} onChange={handleInputChange}>
          <option value="ป" selected>ป</option>
          <option value="ท">ท</option>
        </select>
        </label><br />
        <label>Section <p className="redmark">* </p>: <input type="text" name="section" value={newClass.section} onChange={handleInputChange}/></label><br />
        <label>Start time <p className="redmark">* </p>: <input type="time" name="startTime" value={newClass.startTime} onChange={handleInputChange}/></label><br />
        <label>End time <p className="redmark">* </p>: <input type="time" name="endTime" value={newClass.endTime} onChange={handleInputChange}/></label><br />
        <label>Start Date <p className="redmark">* </p>: <input type="date" name="startDate" value={newClass.startDate ? new Date(newClass.startDate).toISOString().split('T')[0] : ''} onChange={handleInputChange}/></label><br />
        <label>End Date <p className="redmark">* </p>: <input type="date" name="endDate" value={newClass.endDate ? new Date(newClass.endDate).toISOString().split('T')[0] : ''} onChange={handleInputChange}/></label><br />
        <label>Tutor's year: <input type="text" name="tutorYear" value={newClass.tutorYear} onChange={handleInputChange}/></label><br />
        {newClass.professors.map((professor, index) => (
          <div key={index}>
            <label>{`Professor ${index + 1}`} {index === 0 && <p className="redmark">* </p>}{index > 0 && <>&nbsp;&nbsp;&nbsp;</>}:&nbsp; </label>
            <input 
              type="text" 
              onChange={(e) => handleProfessorChange(index, e.target.value)} 
              placeholder={`Professor ${index + 1}`}
              list={`suggestedNames${index}`}
              value={professor}
            />
            <datalist id={`suggestedNames${index}`}> {/* Datalist for suggestions */}
              {suggestedNames.map((name, i) => (
                <option key={i} value={name} />
              ))}
            </datalist>
            {index > 2 && ( // Render remove button only if index is greater than 2 (Professor 3 and beyond)
              <button type="button" className="remove" onClick={() => removeProfessorField(index)}>x</button>
            )}
          </div>
        ))}

        <button type="button" className="addprof" onClick={addProfessorField}>Add Professor</button>
        <br />
        <button type="button" onClick={handleSubmit} className="submit">Submit</button>
      </form>
    </Modal>
  );
}

export default ClassFormModal;
