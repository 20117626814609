import React, { useState } from 'react';
import './Navbar.css';
import Cookies from 'js-cookie';


function Navbar() {
  const userCookie = Cookies.get('userData');
  let role = '';
  if (userCookie) {
    const userData = JSON.parse(atob(userCookie.split('.')[1]));
    role = userData.role;
  }

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <nav className="navbar">
      <ul className="nav-list">
        <li className="navli"><a href="/">Home</a></li>
        <li className="navli"><a href="/table">Makeup Schedule</a></li>
        {(role === 'admin' || role ==='co-admin') && (
          <li className="dropdown" onMouseEnter={handleDropdownToggle} onMouseLeave={handleDropdownToggle}>
            <a href="#" className="dropdown-toggle">Admin Pages</a>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                <li className="navli"><a href="/class-schedule">Add Class</a></li>
                <li className="navli"><a href="/holiday">Add Holiday</a></li>
                {role === 'admin' && (
                  <li className="navli"><a href="/user-access">Permission</a></li>
                )}
                <li className="navli"><a href="/prof">Professor Summary</a></li>
              </ul>
            )}
          </li>
        )}
      </ul>
    </nav>
  );
}

export default Navbar;