import React, { useState, useEffect } from 'react';
import fetch_data from '../Component/fetch-data.js';
import Modal from 'react-modal';
import './AcademicForm.css';

function AcademicModal({ isOpen, onClose, onSelection }) {
  const [classData, setClassData] = useState([]);
  const [selectedYear, setSelectedYear] = useState((new Date().getFullYear() + 543).toString());
  const [selectedSemester, setSelectedSemester] = useState('1');

  useEffect(() => {
    fetch_data.getClassSchedule() 
      .then((response) => response.json())
      .then((classData) => {
        classData.sort((a, b) => (a.subjectCode > b.subjectCode) ? 1 : -1);
        setClassData(classData);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });

  }, []);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleSemesterChange = (event) => {
    setSelectedSemester(event.target.value);
  };

  const handleConfirm = () => {
    if (selectedYear && selectedSemester) {
      console.log(selectedYear, selectedSemester);
      onSelection(selectedYear, selectedSemester);
      onClose();
    } else {
      alert('Please select both academic year and semester.');
    }
  };

  const generateAcademicYearOptions = () => {
    const academicYears = [...new Set(classData.map(item => item.academicYear))];
    academicYears.sort((a, b) => parseInt(a) - parseInt(b));
    return academicYears.map(year => (
      <option key={year} value={year}>
        {year}
      </option>
    ));
  };

  return (
    <Modal className="academic-form center"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Select Academic Year and Semester</h2>
        <div className="dropdown-container">
          <label>Academic year :&nbsp;
            <select name="academicYear" value={selectedYear} onChange={handleYearChange}>
            {generateAcademicYearOptions()}
            </select>
          </label>
        </div>
        <div className="dropdown-container">
            <label>Semester :&nbsp;
            <select name="semester" value={selectedSemester} onChange={handleSemesterChange}>
                <option value="1">1</option>
                <option value="2">2</option>
            </select>
            </label>
        </div>
        <button onClick={handleConfirm}>Confirm</button>
      </Modal>
  );
}

export default AcademicModal;
