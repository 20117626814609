import { Routes, Route } from 'react-router-dom';
import Homepage from './page/Homepage';
import Table from './page/Table';
import UserAccess from './page/User-access';
import ClassSchedule from './page/Class-schedule';
import Holiday from './page/Holiday';
import Navbar from './page/Navbar';
import ProfSummary from './page/ProfSummary';
import Cookies from 'js-cookie';

function AuthorizedRoute({ element, allowedRoles }) {
  const userCookie = Cookies.get('userData');
  if (userCookie) {
    const userData = JSON.parse(atob(userCookie.split('.')[1]));
    if (userData && allowedRoles.includes(userData.role)) {
      return element;
    } else {
      return (
        <div>
          <h1>401 Unauthorized</h1>
          <p>You are not authorized to access this page.</p>
        </div>
      );
    }
  } else {
    return (
      <div>
        <h1>401 Unauthorized</h1>
        <p>You are not authorized to access this page.</p>
      </div>
    );
  }
}

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Homepage />} />
      <Route path="/homepage" element={<Homepage />} />
      <Route path="/table" element={<Table />} />
      <Route
        path="/user-access"
        element={
          <AuthorizedRoute
            element={<UserAccess />}
            allowedRoles={['admin']}
          />
        }
      />
      <Route
        path="/class-schedule"
        element={
          <AuthorizedRoute
            element={<ClassSchedule />}
            allowedRoles={['admin', 'co-admin']}
          />
        }
      />
      <Route path="/prof" element={<ProfSummary />} />
      <Route
        path="/holiday"
        element={
          <AuthorizedRoute
            element={<Holiday />}
            allowedRoles={['admin', 'co-admin']}
          />
        }
      />
    </Routes>
  );
}

export default App;
