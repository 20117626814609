import React, { useState, useEffect } from 'react';
import fetch_data from '../Component/fetch-data.js';
import Modal from './ClassForm';
import AcademicModal from './AcademicForm'
import './Class-schedule.css';
import Navbar from './Navbar';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import manual from '../img/class_adjust_manual.jpg';

function ClassList() {
  const [classData, setClassData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAcademicOpen, setIsAcademicOpen] = useState(true);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [academicYearAdd, setAcademicYearAdd] = useState("");
  const [semesterAdd, setSemesterAdd] = useState("");
  const [editClass, setEditClass] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [showPhoto, setShowPhoto] = useState(false);

  const handleShowPhoto = () => {
    setShowPhoto(true);
  };

  const handleClosePhoto = () => {
    setShowPhoto(false);
  };

  useEffect(() => {
    fetch_data.getClassSchedule() 
      .then((response) => response.json())
      .then((classData) => {
        if (!classData || classData.length === 0) {
          setIsAcademicOpen(false);
        }
        classData.sort((a, b) => (a.subjectCode > b.subjectCode) ? 1 : -1);
        setClassData(classData);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
        setIsAcademicOpen(false);
      });

  }, []);

  function handleEdit(classId) {
    const classToEdit = classData.find(classItem => classItem.id === classId);
    const professorsArray = classToEdit.professors.split(',').map(professor => professor.trim());
    setEditClass({ ...classToEdit, professors: professorsArray, tutorYear: classToEdit.tutorYear === null ? '' : classToEdit.tutorYear });
    handleOpenModal();
  };

  function handleDelete(id) {
    fetch_data.deleteClass(id)
    .then(() => {
      setClassData((prevClassData) => prevClassData.filter(item => item.id !== id));
    })
    .catch((error) => {
      console.error(error);
    })
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenAcademicModal = () => {
    setIsAcademicOpen(true);
  };

  const handleCloseAcademicModal = () => {
    setIsAcademicOpen(false);
  };

  const handleAddClass = (newClass) => {
    if (newClass.id === null) {
      fetch_data.addClass(newClass)
      .then(() => {
        fetch_data.getClassSchedule()
          .then((response) => response.json())
          .then((updatedClassData) => {
            updatedClassData.sort((a, b) => (a.subjectCode > b.subjectCode) ? 1 : -1);
            setClassData(updatedClassData);
          })
          .catch((error) => {
            console.error('Error fetching updated class data:', error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
    } else {
      const startDate = newClass.startDate.includes('T') ? new Date(newClass.startDate).toISOString().split('T')[0] : newClass.startDate;
      const endDate = newClass.endDate.includes('T') ? new Date(newClass.endDate).toISOString().split('T')[0] : newClass.endDate;
      newClass = {
        ...newClass,
        startDate,
        endDate
      };
    
      fetch_data.updateClass(newClass)
      .then(() => {
        const updatedData = classData.map(classItem => {
          if (classItem.id === newClass.id) {
            const professors = newClass.professors.filter(professor => professor !== null).join(',');
            return {
              ...newClass,
              professors
            };
          } else {
            return classItem;
          }
        });
        setClassData(updatedData);
      })
      .catch((error) => {
        console.error(error);
      })
    }
  };

  const importFile = (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    console.log(startDate, endDate, academicYearAdd, semesterAdd);
  
    if (startDate === null || startDate === "" || endDate === null || endDate === "" || academicYearAdd === "" || semesterAdd === "") {
      setResponseStatus("error");
      setResponseMessage("Please select all requirement!");
      fileInput.value = null;
      setIsAlertOpen(true);
          setTimeout(() => {
            setIsAlertOpen(false);
          }, 3000);
    } else if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('startDate', startDate);
      formData.append('endDate', endDate);
      formData.append('academicYear', academicYearAdd);
      formData.append('semester', semesterAdd);
      fetch_data.importClass(formData)
      .then((response) => response.json())
      .then((response) => {
        if (response.code === '000') {
          setResponseStatus("success");
          setResponseMessage(response.message);
          setIsAlertOpen(true);
          setTimeout(() => {
            setIsAlertOpen(false);
          }, 3000);
          fetch_data.getClassSchedule()
          .then((response) => response.json())
          .then((updatedClassData) => {
            setClassData(updatedClassData);
          })
          .catch((error) => {
            console.error('Error fetching updated class data:', error);
          });
        } else {
          setResponseStatus("error");
          setResponseMessage("Data imported failed");
          setIsAlertOpen(true);
          setTimeout(() => {
            setIsAlertOpen(false);
          }, 3000);
        }
        fileInput.value = null;
      })
      .catch((err) => console.error(err));
    }
  }

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedData = classData.map(classItem => {
      return { ...classItem, selected: !selectAll };
    });
    setClassData(updatedData);
  };

  const handleRowSelect = (id) => {
    const updatedData = classData.map(classItem => {
      if (classItem.id === id) {
        return { ...classItem, selected: !classItem.selected };
      }
      return classItem;
    });
    setClassData(updatedData);
  };

  const handleRemoveSelectedRows = () => {
    const selectedIds = classData
        .filter(classItem => classItem.selected)
        .map(selectedItem => selectedItem.id);

        selectedIds.forEach(id => handleDelete(id));
  };

  const handleCopySelectedRows = () => {
    const selectedClasses = classData
    .filter(item => item.selected)
    .map(({ id, professors, startDate, endDate, ...rest }) => {
      const professorsArray = professors.split(',').map(professor => professor.trim());
      const formattedStartDate = startDate.split('T')[0];
      const formattedEndDate = endDate.split('T')[0];
      return {
        ...rest,
         id: null,
         professors: professorsArray,
         startDate: formattedStartDate,
         endDate: formattedEndDate
        }
      });
    console.log(selectedClasses);


    selectedClasses.forEach(classItem => handleAddClass(classItem));
  };

  function handleExceptedChange(classId, currentExcepted) {
    const newExcepted = currentExcepted === '1' ? '0' : '1';
    const data = { excepted: newExcepted };
    fetch_data.updateExcepted(classId, data)
    .then(() => {
      setClassData(prevData =>
        prevData.map(classItem =>
          classItem.id === classId
            ? { ...classItem, excepted: newExcepted }
            : classItem
        )
      );
    })
    .catch((error) => {
      console.error(error);
    })
  }

  const generateAcademicYearOptions = () => {
    const currentYear = new Date().getFullYear() + 543;
    const options = [];
    for (let i = currentYear - 2; i <= currentYear + 2; i++) {
      options.push(<option key={i} value={i}>{i}</option>);
    }
    return options;
  };
  
  const filterAcademic = (selectedYear, selectedSemester) => {
    const filteredData = classData.filter(item => item.academicYear === selectedYear && item.semester === selectedSemester);
    setClassData(filteredData.sort((a, b) => (a.subjectCode > b.subjectCode) ? 1 : -1));
    console.log(filteredData);
  };
  
  return (
    <div className="bg">
      <Navbar />
      <div className="topbutton" style={{ display: 'flex' }}>
      
      <button className="add" onClick={handleShowPhoto} >Manual</button>
      {/* Use conditional rendering to display the modal */}
      {showPhoto && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleClosePhoto}>&times;</span>
            <img src={manual} alt="Your Photo" />
          </div>
        </div>
      )}
   
  <button className="add" onClick={handleOpenModal} style={{ marginRight: '1%', marginLeft:"1%" }}>Add Class</button>
  {/* Add import file button */}
  <div className="importbg">
  <div className="file-input-container">
    <form encType="multipart/form-data">
      <label htmlFor="file" className="custom-file-label">Import File</label>
      <input type="file" id="file" name="file" onChange={importFile} />
    </form>
  </div>
    <div>
      <a style={{ fontSize: '12px', marginLeft: '10px' }} href="/ExampleInputFormat.xlsx" download="ExampleInputFormat.xlsx">
      .Download Excel for Input data
      </a>
    </div>
    <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between',alignItems:'center' }}>
  <div>
    <div style={{ marginBottom: '10px' }}>
      <label style={{ fontSize: '12px' }}>
        <span style={{ color: 'red' }}>* </span>
        <input type="date" name="startDate" onChange={(e) => setStartDate(e.target.value)} /> วันเปิดภาคเรียน
      </label>
      <label style={{ fontSize: '12px' }}>
        <span style={{ color: 'red' }}>* </span>
        <input type="date" name="endDate" onChange={(e) => setEndDate(e.target.value)} /> วันปิดภาคเรียน
      </label>
    </div>
    <div>
      <label style={{ fontSize: '12px' }}>
        <span style={{ color: 'red' }}>* </span>
        <select onChange={(e) => setAcademicYearAdd(e.target.value)}>
          <option value="">Select Academic Year</option>
          {generateAcademicYearOptions()}
        </select> ปีการศึกษา
      </label>
      <label style={{ fontSize: '12px' }}>
        <span style={{ color: 'red' }}>* </span>
        <select onChange={(e) => setSemesterAdd(e.target.value)}>
          <option value="">Select Semester</option>
          <option value="1">1</option>
          <option value="2">2</option>
        </select> ภาคการศึกษา
      </label>
    </div>
    </div>
    </div>
  </div>
    <div className="selectall">
      <button className="copybutton" onClick={handleCopySelectedRows}>Copy</button>
      <button className="removebutton" onClick={handleRemoveSelectedRows}>Remove</button>
      <label className="checkall">Select all
        <input type="checkbox" onChange={toggleSelectAll} checked={selectAll}/>
      </label>
    </div>
  

</div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onAddClass={handleAddClass}
        editClass={editClass}
      />
      <AcademicModal
        isOpen={isAcademicOpen}
        onClose={handleCloseAcademicModal}
        onSelection={filterAcademic}
      />
      <Snackbar open={isAlertOpen} autoHideDuration={2000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={responseStatus}>
          {responseMessage}
        </Alert>
      </Snackbar>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <table className="class-table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Year</th>
              <th>Class Day</th>
              <th>Subject Code</th>
              <th>Subject Name</th>
              <th>Section</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Professors</th>
              <th>Excepted</th>
              <th></th>
            </tr>
          </thead>
          <tbody style={{ height: '200px' }}>
            {classData.map((classItem, index) => (
              <tr key={classItem.id}>
                {/* Data cells */}
                <td>{(index + 1)}</td>
                <td>{classItem.yearOfstudy}</td>
                <td>{classItem.classDay}</td>
                <td>{classItem.subjectCode}</td>
                <td>{classItem.subjectName}</td>
                <td>{classItem.section}</td>
                <td>{classItem.startTime}</td>
                <td>{classItem.endTime}</td>
                <td>{classItem.startDate.substring(0, 10)}</td>
                <td>{classItem.endDate.substring(0, 10)}</td>
                <td>
          {[
            classItem.professor1,
            classItem.professor2,
            classItem.professor3,
            classItem.professor4,
            classItem.professor5,
            classItem.professor6,
            classItem.professor7,
            classItem.professor8,
            classItem.professor9,
            classItem.professor10,
            classItem.professor11,
            classItem.professor12
          ]
            .filter(professor => professor) // Filter out undefined or empty values
            .join(", ")
          }
        </td>
        <td>
            <input 
              type="checkbox" 
              checked={classItem.excepted === '1'} 
              onChange={() => handleExceptedChange(classItem.id, classItem.excepted)} 
            />
          </td>
                {/* Checkbox for row selection */}
                <td><input type="checkbox" onChange={() => handleRowSelect(classItem.id)} checked={classItem.selected} /></td>
                <td className="edit-button"><button className="edit-button" onClick={() => handleEdit(classItem.id)}>Edit</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ClassList;