import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import fetch_data from '../Component/fetch-data.js';

Modal.setAppElement('#root');

function HolidayFormModal({ isOpen, onClose, onAddHoliday, editHoliday }) {
  const [newHoliday, setNewHoliday] = useState({
    id: null,
    day: '',
    month: '',
    date: '',
    name: '',
    type: ''
  });

  useEffect(() => {
    console.log(editHoliday);

      if (editHoliday) {
        setNewHoliday(editHoliday);
      }
  }, [editHoliday]);

  const monthAbbreviations = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewHoliday((prevHoliday) => ({
      ...prevHoliday,
      [name]: value,
    }));

    if (name === 'date') {
      const selectedDate = new Date(value);
      const dayOfWeek = selectedDate.toLocaleString('default', { weekday: 'long' });
      const monthIndex = selectedDate.getMonth();
      const monthAbbreviation = monthAbbreviations[monthIndex];

      setNewHoliday((prevHoliday) => ({
        ...prevHoliday,
        day: dayOfWeek,
        month: monthAbbreviation,
      }));
    }
  };

  const handleSubmit = () => {
    onAddHoliday(newHoliday);
    setNewHoliday({
        id: null,
        day: '',
        month: '',
        date: '',
        name: '',
        type: ''
    });
    onClose();
  };

  return (
    <Modal className="holiday-form"
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Add Holiday Modal"
    >
      <span className="close" type="button" onClick={onClose}>X</span>
      <h2>Add New Holiday</h2>
      <form>
        <label>Date <p className="redmark">* </p>: <input type="date" name="date" value={newHoliday.date ? new Date(newHoliday.date).toISOString().split('T')[0] : ''} onChange={handleInputChange}/></label><br />
        <label>Name <p className="redmark">* </p>: <input type="text" name="name" value={newHoliday.name} onChange={handleInputChange}/></label><br />
        <label>Type <p className="redmark">* </p>: <input type="text" name="type" value={newHoliday.type} onChange={handleInputChange}/></label><br />
        <br /><button className="submit" type="button" onClick={handleSubmit}>Submit</button>
      </form>
    </Modal>
  );
}

export default HolidayFormModal;
