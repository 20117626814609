import React from 'react';

const DegreeDropdown = ({ selectedValue, onChange }) => {
  const degreeOptions = [
    { value: 'ปริญญาตรี', label: 'ปริญญาตรี' },
    { value: 'ปริญญาโทและปริญญาเอก', label: 'ปริญญาโทและปริญญาเอก' },
  ];

  return (
    <select value={selectedValue} onChange={onChange}>
      {degreeOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default DegreeDropdown;