import React, { useState } from 'react';
import Cookies from 'js-cookie';
const url = 'https://smc.raikmitl.com/api';
class Fetch_data {

    getHoliday(){
        return fetch(`${url}/holidays`, { method: 'GET' });
    }

    getClassSchedule(){
        return fetch(`${url}/class-schedule`, { method: 'GET' });
    }

    getConflictClass(){
        return fetch(`${url}/conflict`, { method: 'GET' });
    }

    getMakeupSchedule(){
        return fetch(`${url}/makeup-class`, { method: 'GET' });
    }

    getUser(){
        return fetch(`${url}/user`, { method: 'GET', headers: {'Authorization': `Bearer ${Cookies.get('userData')}` } });
    }

    updateUserRole(data){
        return fetch(`${url}/user`, { method: 'PATCH', headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('userData')}` }, body: JSON.stringify(data) });
    }

    addHoliday(data){
        return fetch(`${url}/holidays`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('userData')}` }, body: JSON.stringify(data) });
    }

    addClass(data){
        return fetch(`${url}/class-schedule`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('userData')}` }, body: JSON.stringify(data) });
    }

    addGoogleHolidays() {
        return fetch(`${url}/google-holidays`, { method: 'POST', headers: { 'Authorization': `Bearer ${Cookies.get('userData')}` } });
    }

    importClass(file){
        return fetch(`${url}/import-class`, { method: 'POST', headers: {'Authorization': `Bearer ${Cookies.get('userData')}` }, body: file });
    }

    updateClass(data) {
        return fetch(`${url}/class-schedule/${data.id}`, { method: 'PUT', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('userData')}` }, body: JSON.stringify(data) });
    }

    updateExcepted(classId, data){
        return fetch(`${url}/class-excepted/${classId}`, { method: 'PATCH', headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('userData')}` }, body: JSON.stringify(data) });
    }

    updateHoliday(data) {
        return fetch(`${url}/holidays/${data.id}`, { method: 'PUT', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('userData')}` }, body: JSON.stringify(data) });
    }

    deleteHoliday(holidayId) {
        return fetch(`${url}/holidays/${holidayId}`, { method: 'DELETE', headers: {'Authorization': `Bearer ${Cookies.get('userData')}` } });
    }

    deleteClass(classId) {
        return fetch(`${url}/class-schedule/${classId}`, { method: 'DELETE', headers: {'Authorization': `Bearer ${Cookies.get('userData')}` } });
    }

    deleteUser(userId) {
        return fetch(`${url}/user/${userId}`, { method: 'DELETE', headers: {'Authorization': `Bearer ${Cookies.get('userData')}` } });
    }
}

export default new Fetch_data();