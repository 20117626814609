import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import fetch_data from '../Component/fetch-data.js';
import './FilterForm.css';
import SecondModal from './SecondModal.jsx';

Modal.setAppElement('#root');

function FilterFormModal({ isOpen, onClose, onAddClass }) {
  const [selectedYears, setSelectedYears] = useState([]); // Changed to array
  const [classData, setClassData] = useState([]);
  const [selectAllSubjects, setSelectAllSubjects] = useState(false);
  const [subjectCheckboxes, setSubjectCheckboxes] = useState([]);
  const [selectedData, setSelectedData] = useState([]); // Track selected data
  const [secondModalOpen, setSecondModalOpen] = useState(false); // New state for the second modal

  useEffect(() => {
    const fetchData = async () => {
      try {
        const classResponse = await fetch_data.getClassSchedule();
        const classData = await classResponse.json();
        setClassData(classData);

        // Create checkboxes for each subject
        const checkboxes = classData.map(item => ({
          id: item.id,
          checked: false
        }));
        setSubjectCheckboxes(checkboxes);
      } catch (error) {
        console.error('Error fetching class data:', error);
      }
    };

    fetchData();
  }, []);

  const handleYearCheckboxChange = year => {
    // Toggle the year in selectedYears
    const updatedSelectedYears = selectedYears.includes(year)
      ? selectedYears.filter(selectedYear => selectedYear !== year)
      : [...selectedYears, year];
    setSelectedYears(updatedSelectedYears);
  };

  const handleSelectAllChange = () => {
    let updatedCheckboxes;
    if (selectedYears.length > 0) {
      updatedCheckboxes = subjectCheckboxes.map(checkbox => ({
        ...checkbox,
        checked: !selectAllSubjects && selectedYears.includes(classData.find(item => item.id === checkbox.id)?.yearOfstudy)
      }));
    } else {
      updatedCheckboxes = subjectCheckboxes.map(checkbox => ({
        ...checkbox,
        checked: !selectAllSubjects
      }));
    }
    setSubjectCheckboxes(updatedCheckboxes);
    setSelectAllSubjects(!selectAllSubjects);
  };

  const handleSubjectCheckboxChange = (id) => {
    const updatedCheckboxes = subjectCheckboxes.map(checkbox =>
      checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
    );
    setSubjectCheckboxes(updatedCheckboxes);
  };

  const openSecondModal = () => {
    setSecondModalOpen(true);
    // Filter selected data based on checked checkboxes
    const selected = filteredUniqueClassData.filter(item => subjectCheckboxes.find(checkbox => checkbox.id === item.id && checkbox.checked));
    setSelectedData(selected);
  };

  const closeSecondModal = () => {
    setSecondModalOpen(false);
  };

  // Get unique years from class data
  const uniqueYears = [...new Set(classData.map(item => item.yearOfstudy))];

  // Filter class data based on selected years
  const filteredClassData = classData.filter(item =>
    selectedYears.length === 0 || selectedYears.includes(item.yearOfstudy)
  );

  const uniqueSubjects = new Set();
  const filteredUniqueClassData = filteredClassData.filter(item => {
    if (!uniqueSubjects.has(item.subjectName)) {
      uniqueSubjects.add(item.subjectName);
      return true;
    }
    return false;
  });

  return (
    <Modal
      className="filter-form"
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Add Class Modal"
    >
      <span onClick={onClose} className="close">X</span>
      <div>
        <div className="checkbox-head">
          {uniqueYears.map(year => (
            <label key={year}>
              <input
                type="checkbox"
                className="checkbox"
                checked={selectedYears.includes(year)}
                onChange={() => handleYearCheckboxChange(year)}
              />
              <span className="small-box"></span>
              <span className="box-content">Year {year}</span>
            </label>
          ))}
        </div>
      </div>
      
      <div>
        <h2>Filtered Class Data:</h2>
        <label>
          <input
            type="checkbox"
            className="checkbox"
            checked={selectAllSubjects}
            onChange={handleSelectAllChange}
          />
          <span className="small-box"></span>
          <span className="box-content">Select All</span>
        </label>
        <ul>
          {filteredUniqueClassData.map(item => (
            <li key={item.id}>
              <input
                type="checkbox"
                className="checkbox"
                checked={subjectCheckboxes.find(checkbox => checkbox.id === item.id)?.checked || false}
                onChange={() => handleSubjectCheckboxChange(item.id)}
              />
              <span className="small-box"></span>
              <span className="box-content">{item.subjectName} - Year {item.yearOfstudy}</span>
            </li>
          ))}
        </ul>
      </div>

      {/* Render the button to open the second modal */}
      <button onClick={openSecondModal}>Check subject</button>

      {/* Render the SecondModal component */}
      <SecondModal isOpen={secondModalOpen} onClose={closeSecondModal} selectedData={selectedData} />
    </Modal>
  );
}

export default FilterFormModal;
