import React, { useState, useEffect } from 'react';
import fetch_data from '../Component/fetch-data.js';
import './Holiday.css';
import Navbar from './Navbar';
import Modal from './HolidayForm';
import manual from '../img/Holiday_manual.jpg';

function HolidayList() {
  const [holidayData, setHolidayData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showPhoto, setShowPhoto] = useState(false);
  const [editHoliday, setEditHoliday] = useState(null);

  const handleShowPhoto = () => {
    setShowPhoto(true);
  };

  const handleClosePhoto = () => {
    setShowPhoto(false);
  };

  useEffect(() => {
    fetch_data.getHoliday() 
      .then((response) => response.json())
      .then((holidayData) => {
        console.log('Holidaydata : ', holidayData);
        setHolidayData(holidayData);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  }, []);

  function handleDelete(id) {
    fetch_data.deleteHoliday(id)
    .then(() => {
      console.log('Holiday deleted successfully with ID:', id);
      setHolidayData((prevHolidayData) => prevHolidayData.filter(item => item.id !== id));
    })
    .catch((error) => {
      console.error(error);
    })
  };

  const handleAddHoliday = (newHoliday) => {
    if (newHoliday.id === null) {
      fetch_data.addHoliday(newHoliday)
      .then(() => {
        fetch_data.getHoliday().then((response) => response.json())
          .then((updatedHolidayData) => {
            setHolidayData(updatedHolidayData);
          })
          .catch((error) => {
            console.error('Error fetching updated holiday data:', error);
          });
      })
      .catch((error) => {
        console.error('Error adding holiday:', error);
      });
    } else {
      fetch_data.updateHoliday(newHoliday)
      .then(() => {
        const updatedData = holidayData.map(holidayItem => {
          if (holidayItem.id === newHoliday.id) {
            return {
              ...newHoliday
            };
          } else {
            return holidayItem;
          }
        });
        setHolidayData(updatedData);
      })
      .catch((error) => {
        console.error(error);
      })
    }
  };

  function handleEdit(holidayId) {
    const holidayToEdit = holidayData.find(holidayItem => holidayItem.id === holidayId);
    const monthAbbreviations = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const selectedDate = new Date(holidayToEdit.date);
    const dayOfWeek = selectedDate.toLocaleString('default', { weekday: 'long' });
    const monthIndex = selectedDate.getMonth();
    const monthAbbreviation = monthAbbreviations[monthIndex];
    setEditHoliday({ ...holidayToEdit, day: dayOfWeek, month: monthAbbreviation });
    handleOpenModal();
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAddGoogleHolidays = () => {
    fetch_data.addGoogleHolidays()
      .then(() => {
        console.log('Google holidays added successfully');
        fetch_data.getHoliday()
          .then((response) => response.json())
          .then((updatedHolidayData) => {
            setHolidayData(updatedHolidayData);
          })
          .catch((error) => {
            console.error('Error fetching updated holiday data:', error);
          });
      })
      .catch((error) => {
        console.error('Error adding Google holidays:', error);
      });
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedData = holidayData.map(holidayItem => {
      return { ...holidayItem, selected: !selectAll };
    });
    setHolidayData(updatedData);
  };

  const handleRowSelect = (id) => {
    const updatedData = holidayData.map(holidayItem => {
      if (holidayItem.id === id) {
        return { ...holidayItem, selected: !holidayItem.selected };
      }
      return holidayItem;
    });
    setHolidayData(updatedData);
  };

  const handleRemoveSelectedRows = () => {
    const selectedIds = holidayData
        .filter(holidayItem => holidayItem.selected)
        .map(selectedItem => selectedItem.id);

        selectedIds.forEach(id => handleDelete(id));
  };

  return (
    <div className="bg">
      <div>
        <Navbar />
        <div style={{ display: 'flex'}}>
        <button className='addh' onClick={handleShowPhoto}>Manual</button>
        {showPhoto && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleClosePhoto}>&times;</span>
            <img src={manual} alt="Your Photo" />
          </div>
        </div>
      )}
        <button className="addh" onClick={handleOpenModal} style={{ marginLeft: '1%' }}>Add Holidays</button>
        <button className="addh2" onClick={handleAddGoogleHolidays} style={{ marginRight: '35%', marginLeft:"1%" }}>Add Google Holidays</button>
        <div className="selectall2">
          <button className="removebutton" onClick={handleRemoveSelectedRows}>Remove</button>
          <label className="checkall">Select all
            <input type="checkbox" onChange={toggleSelectAll} checked={selectAll}/>
          </label>
        </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onAddHoliday={handleAddHoliday}
          editHoliday={editHoliday}
        />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <table className="holiday-table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Day</th>
                <th>Month</th>
                <th>Date</th>
                <th>Name</th>
                <th>Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{ height: '200px' }}>
              {holidayData.map((holidayItem, index) => (
                <tr key={holidayItem.id}>
                  <td>{(index + 1)}</td>
                  <td>{holidayItem.day}</td>
                  <td>{holidayItem.month}</td>
                  <td>{holidayItem.date.substring(0, 10)}</td>
                  <td>{holidayItem.name}</td>
                  <td>{holidayItem.type}</td>
                  <td className="checkbox-column"><input type="checkbox" onChange={() => handleRowSelect(holidayItem.id)} checked={holidayItem.selected} /></td>
                  <td className="edit-button"><button className="edit-button" onClick={() => handleEdit(holidayItem.id)}>Edit</button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default HolidayList;
