import React, { useState, useEffect } from 'react';
import './Table.css'
import './Navbar.css';
import fetch_data from '../Component/fetch-data.js';
import Navbar from './Navbar';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import FilterModal from './FilterForm';
import ConfigModal from './ConfigForm';
import DegreeDropdown from './DegreeDropdown.jsx';
import { fontTH } from '../Component/Font/THSarabun-normal.js';
import { fontbold } from '../Component/Font/THSarabunIT๙ Bold-normal';
import { fonteng } from '../Component/Font/Calibri Regular-normal.js';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import { img } from './img.jsx';
import loadingGif from '../img/Hourglass.gif';
import manual from '../img/make-up-manaual_1.jpg';
import AcademicModal from './AcademicForm'


function Calendar(){
    const [currentDate, setCurrentDate] = useState(new Date());
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [isConfigOpen, setIsConfigOpen] = useState(false);
    const [classData, setClassData] = useState([]);
    const [holidayData, setHolidayData] = useState([]);
    const [makeupClassData, setMakeupData] = useState([]);
    const [filteredMakeupData, setFilteredMakeupData] = useState([]);
    const [selectedMakeupClass, setSelectedMakeupClass] = useState([]);
    const [startSelectedMonth, setStartSelectedMonth] = useState(new Date().getMonth());
    const [endSelectedMonth, setEndSelectedMonth] = useState(new Date().getMonth() + 1);
    const [startSelectedYear, setStartSelectedYear] = useState(new Date().getFullYear());
    const [endSelectedYear, setEndSelectedYear] = useState(new Date().getFullYear());
    const startDate = new Date(startSelectedYear, startSelectedMonth, 1); 
    startDate.setDate(startDate.getDate() + 1);
    const [startMonth, setStartMonth] = useState(startDate.toISOString());
    const endDate = new Date(endSelectedYear, endSelectedMonth, 1); 
    const [endMonth, setEndMonth] = useState(endDate.toISOString());
    const [subjectSectionCounts, setSubjectSectionCounts] = useState({});
    const [loading, setLoading] = useState(true); // Loading state
    const [showPhoto, setShowPhoto] = useState(false);
    const [isAcademicOpen, setIsAcademicOpen] = useState(true);
    const [translatedMonth, setTranslatedMonth] = useState('');
    const [semester, setSemester] = useState('');
    const [academicYear, setAcademicYear] = useState('');

  // Translation function
  const translateMonthToThai = (englishMonth) => {
    const thaiMonths = [
      'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
      'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
    ];

    const englishMonths = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const index = englishMonths.indexOf(englishMonth);
    return thaiMonths[index];
  };

    const handleShowPhoto = () => {
      setShowPhoto(true);
    };
  
    const handleClosePhoto = () => {
      setShowPhoto(false);
    };

    useEffect(() => {
        const fetchData = async() => {
            const holidayResponse = await fetch_data.getHoliday();
            const classResponse = await fetch_data.getClassSchedule();
            const makeupClassResponse = await fetch_data.getMakeupSchedule();
            const holidayData = await holidayResponse.json();
            const classData = await classResponse.json();
            const makeupClassData = await makeupClassResponse.json();

            if (!classData || classData.length === 0) {
              setIsAcademicOpen(false);
            }

            makeupClassData.sort((a, b) => {
              return a.subjectCode.localeCompare(b.subjectCode);
            });

            setFilteredMakeupData(makeupClassData.filter(item => item.yearOfstudy !== 'Graduate'));            
            setHolidayData(holidayData);
            setClassData(classData);
            setMakeupData(makeupClassData);
            setLoading(false); // Set loading state to false after data fetching is complete
        }
        fetchData();
    }, []);

    useEffect(() => {
        const counts = {};
        filteredMakeupData.forEach(item => {
          const key = `${item.subjectName}-${item.section}`;
          counts[key] = (counts[key] || 0) + 1;
        });
        setSubjectSectionCounts(counts);
    }, [filteredMakeupData]);

    const uniqueSubjectSections = Object.keys(subjectSectionCounts);

    const renderCalendar = (month) => {
        const year = currentDate.getFullYear();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        const daysInMonth = lastDay.getDate();
        const firstDayOfWeek = firstDay.getDay();

        const calendar = [];
        let dayCounter = 1;

        for (let i = 0; i < 6; i++) {
            const row = [];
            for (let j = 0; j < 7; j++) {
                if ((i === 0 && j < firstDayOfWeek) || dayCounter > daysInMonth) {
                    row.push(<td key={j}></td>);
                } else {
                    const currentDate = new Date(year, month, dayCounter);
                    const matchingClass = classData.find(
                        (classItem) => new Date(classItem.date).toDateString() === currentDate.toDateString()
                    );

                    const matchingHoliday = holidayData.find(
                        (holidayItem) => new Date(holidayItem.date).toDateString() === currentDate.toDateString()
                    );

                    const matchingMakeupClass = makeupClassData.reduce((result, makeupClassItem) => {
                        if (new Date(makeupClassItem.makeupDate).toDateString() === currentDate.toDateString()) {
                            result.isMatch = true;
                            if (!result.matchingClass) {
                                result.matchingClass = [];
                            }
                            result.matchingClass.push(makeupClassItem);
                        }
                        return result;
                    }, { isMatch: false, matchingClass: [] });
                    
                    row.push(<td key={j}>
                        <div className="number-square" style={{background: matchingMakeupClass.isMatch ? '#FF5733 '
                                                                : matchingClass && matchingHoliday ? 'linear-gradient(to right, blue 50%, green 50%)' 
                                                                : matchingClass ? 'blue'
                                                                : matchingHoliday ? '#27ae60'
                                                                : ''}}>{dayCounter}{matchingMakeupClass.isMatch && (
                                                                <button className="makeup-config-button" onClick={() => {handleMakeupConfig(matchingMakeupClass.matchingClass);}}>
                                                                    <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: '9px' }} />
                                                                </button>
                                                            )}</div>
                        </td>);
                    dayCounter++;
                }
            }
            calendar.push(<tr key={i}>{row}</tr>);
        }

        return calendar;
    }

  const [selectedDegree, setSelectedDegree] = useState('ปริญญาตรี');
  

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => {
        const nextMonth = new Date(prevDate);
        nextMonth.setMonth(prevDate.getMonth() + 1);
        return nextMonth;
      });
  }

  const handlePrevMonth = () => {
    setCurrentDate((prevDate) => {
        const prevMonth = new Date(prevDate);
        prevMonth.setMonth(prevDate.getMonth() - 1);
        return prevMonth;
      });
  }

  const getMonthName = (month) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[month];
  }

  const handleMakeupConfig = (selectedMakeupClass) => {

    if (selectedMakeupClass) {
      setSelectedMakeupClass(selectedMakeupClass);
      setIsConfigOpen(true);
    }
};

const handleUpdateFromConfig = (updatedData) => {
  setMakeupData((prevMakeupClassData) => {
    const updatedArray = prevMakeupClassData.map((item) => {
      if (item.id === updatedData.id) {
        return {
          ...item,
          makeupDate: updatedData.makeupDate,
          makeupTimeSlot: updatedData.makeupTimeSlot,
        };
      }
      return item;
    });
    return updatedArray;
  });
};

const hasConflict = (id, newDate, newTimeSlot) => {
  const conflictingClass = makeupClassData.find((classItem) => {
    if (classItem.id === id) {
      return false;
    }

    if (classItem.makeupDate === newDate) {
      const conflictingSlot = classItem.makeupTimeSlot.find((slot) => {
        const existingStartTime = new Date(`1970-01-01T${slot.startTime}Z`);
        const existingEndTime = new Date(`1970-01-01T${slot.endTime}Z`);
        const newStartTime = new Date(`1970-01-01T${newTimeSlot.min}Z`);
        const newEndTime = new Date(`1970-01-01T${newTimeSlot.max}Z`);

        const overlap =
          (newStartTime >= existingStartTime && newStartTime < existingEndTime) ||
          (newEndTime > existingStartTime && newEndTime <= existingEndTime) ||
          (newStartTime <= existingStartTime && newEndTime >= existingEndTime);

        return overlap;
      });

      return conflictingSlot !== undefined;
    }

    return false;
  });

  return conflictingClass !== undefined;
};

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('th-TH', options);
  };

  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(':');
    return `${hours}.${minutes}`;
  }

function findMinMaxTimes(times) {
  const startTimes = times.map(time => new Date(`1970-01-01T${time.startTime}Z`));
  const endTimes = times.map(time => new Date(`1970-01-01T${time.endTime}Z`));

  const minTime = new Date(Math.min(...startTimes));
  const maxTime = new Date(Math.max(...endTimes));

  return {
      min: formatTime(minTime.toISOString().substr(11, 8)),
      max: formatTime(maxTime.toISOString().substr(11, 8)),
  };
  }

  let headerText;
  const [currentDatePDF, setCurrentDatePDF] = useState(new Date());
  const [currentYear, setCurrentYear] = useState(currentDatePDF.getFullYear() + 543);
  const getAcademicHead = (date, currentYear) => {
    const month = date.getMonth();;
    
  
    // Define the start and end months for each semester
    const semester1StartMonth = 6; // July
    const semester1EndMonth = 9;   // October
    const semester2StartMonth = 10; // November
    const semester2EndMonth = 3;   // March
    let semester;
    if (month >= semester1StartMonth && month <= semester1EndMonth) {
      semester = 1;
    } else if (month >= semester2StartMonth || month <= semester2EndMonth) {
      semester = 2;
    }
  
    // Calculate the academic year based on the semester
    const academicYear = semester === 1 ? currentYear : currentYear - 1;
  
    // Create the academic semester description
    const academicSemester = `ภาคเรียนที่ ${semester} ปีการศึกษา ${academicYear}`;
    const academicHead = `ภาคเรียนที่ ${semester} ปีการศึกษา ${academicYear}`
  
    return academicSemester;
  };
  const getAcademicSemester = (date, currentYear) => {
    const month = date.getMonth();

    // Define the start and end months for each semester
    const semester1StartMonth = 6; // July
    const semester1EndMonth = 9;   // October
    const semester2StartMonth = 10; // November
    const semester2EndMonth = 3;   // March (Ends in February but spans into March)
    let semester;

    // Check if the month falls within semester 1 range
    if (month >= semester1StartMonth && month <= semester1EndMonth) {
        semester = 1;
    }
    // Check if the month falls within semester 2 range
    else if (month >= semester2StartMonth || month <= semester2EndMonth) {
        semester = 2;
    }
    // Handle scenarios where the month falls outside both semester ranges
    else {
        // For example, you might consider assigning a default semester
        // semester = 1; // or semester = 2; or any other default value
        // You can also return a message indicating that the semester is not determined
        return "Semester not determined"; 
    }

    // Calculate the academic year based on the semester
    const academicYear = semester === 1 ? currentYear : currentYear - 1;

    // Create the academic semester description
    const academicSemester = `ภาคเรียนที่ ${semester} ปีการศึกษา ${academicYear}`;
    const academicHead = `ภาคเรียนที่ ${semester} ปีการศึกษา ${academicYear}`

    return academicSemester;
};

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDatePDF(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);



  
  
  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(':');
    return `${hours}.${minutes}`;
  }

  function findMinTimes(times) {
    const startTimes = times.map(time => new Date(`1970-01-01T${time.startTime}Z`));
    const minTime = new Date(Math.min(...startTimes));
    return formatTime(minTime.toISOString().substr(11, 8));
  }

    function findMaxTimes(times) {
      const endTimes = times.map(time => new Date(`1970-01-01T${time.endTime}Z`));
      const maxTime = new Date(Math.max(...endTimes));
    return formatTime(maxTime.toISOString().substr(11, 8));
      
    }
  const generatePdf = () => {
    const pdf = new jsPDF();
    if (selectedDegree === 'ปริญญาตรี') {
      const selectedMonth = translateMonthToThai(
        document.getElementById("startMonth").options[startSelectedMonth].text
      );
      
      
      headerText = 'หลักสูตรวิศวกรรมหุ่นยนต์และปัญญาประดิษฐ์ (หลักสูตรนานาชาติ) และหลักสูตรวิชาโทปัญญาประดิษฐ์';
      pdf.addImage(img,'JPEG',18,15,18,18) //x,y,w,h image
      //font
      pdf.addFileToVFS('Calibri.ttf', fonteng);
    pdf.addFont('Calibri.ttf', 'Calibri', 'normal');
    pdf.setFont('Calibri');

    pdf.addFileToVFS('THSarabun-normal.ttf', fontTH);
    pdf.addFont('THSarabun-normal.ttf', 'THSarabun-normal', 'normal');
    pdf.setFont('THSarabun-normal');

    
    pdf.setFontSize(14);
    pdf.text('คณะวิศวกรรมศาสตร์ ภาควิชาวิศวกรรมหุ่นยนต์และปัญญาประดิษฐ์',40,37);
    pdf.text('อว ๗๐๐๒.๑๓/',30,47);
    const thaiDate = { day: 'numeric', month: 'long', year: 'numeric', weekday: 'long' };
    pdf.text('ขออนุมัติสอนชดเชยหลักสูตรวิศวกรรมหุ่นยนต์และปัญญาประดิษฐ์ (หลักสูตรนานาชาติ) และหลักสูตรวิชาโทปัญญาประดิษฐ์',30,57);
    const academicSemester = `ภาคเรียนที่ ${semester} ปีการศึกษา ${academicYear}`;
    const academicHead = `ภาคเรียนที่ ${semester} ปีการศึกษา ${academicYear}`;
    pdf.text(`ประจำเดือน${selectedMonth} ${academicSemester}`, 30,64);
    
    
    let resultText = '';  // Declare the variable outside the loop

    const filteredMakeup = makeupClassData.filter(item => {
      const itemMonth = new Date(item.date);
      const startDate = new Date(startMonth);
      const endDate = new Date(endMonth);
  
      // Additional condition to filter out 'Graduate' yearOfStudy
      return itemMonth >= startDate && itemMonth <= endDate && item.yearOfstudy !== 'Graduate';
  });
  let isFirstTimeResultText = true;
  filteredMakeup.forEach((makeupItem, index) => {
  const conflictWith = makeupItem.conflictWith;

  if (conflictWith) {
    let conflictNames = Array.isArray(conflictWith.name) ? conflictWith.name : [conflictWith.name];

    // Filter out duplicate conflict names
    conflictNames = [...new Set(conflictNames)];

    // Filter makeupClassData to remove duplicates based on date and conflict names
    const uniqueMakeupData = [];
    const seenDates = new Set(); // Track dates to detect duplicates
    makeupClassData.forEach(item => {
        const itemDate = new Date(item.date);
        const itemMonth = itemDate.getMonth() + 1; // Adding 1 because getMonth returns zero-based month index

        // Convert startMonth and endMonth to Date objects
        const startDate = new Date(startMonth);
        const endDate = new Date(endMonth);

        // Get month numbers for comparison
        const startMonthNumber = startDate.getMonth() + 1;
        const endMonthNumber = endDate.getMonth() + 1;

        // Check if the item date falls within the range of startMonth and endMonth
        if (
            !seenDates.has(itemDate.toISOString()) &&
            itemMonth >= startMonthNumber &&
            itemMonth <= endMonthNumber
        ) {
            seenDates.add(itemDate.toISOString());
            uniqueMakeupData.push(item);
        }
    });

    const filteredMakeup = uniqueMakeupData;

    const conflictText = conflictNames.join(', ');

    // Append the conflictText to resultText
    if (isFirstTimeResultText === true) {
        if (filteredMakeup.length === 1) {
            if (!resultText.includes(conflictText)) {
                resultText += `${conflictText}`;
            }
            isFirstTimeResultText = false;
        } else {
            if (index === 0) {
                resultText += `${conflictText}`;
            } else if (index === filteredMakeup.length - 1) {
                if (!resultText.includes(conflictText)) {
                    resultText += ` และ ${conflictText}`;
                }
                isFirstTimeResultText = false;
            } else {
                if (!resultText.includes(conflictText)) {
                    resultText += `, ${conflictText}`;
                }
            }
        }
    }
}
  });
  const maxWidth = 170;
    pdf.text('เรียน', 20, 74);
    pdf.text('คณบดีคณะวิศวกรรมศาสตร์', 30, 74);
    pdf.text('ด้วยหลักสูตรวิศวกรรมหุ่นยนต์และปัญญาประดิษฐ์ (หลักสูตรนานาชาติ) และหลักสูตรวิชาโทปัญญาประดิษฐ์ ภาควิชาวิศวกรรม',32,83);
    pdf.text(`หุ่นยนต์และปัญญาประดิษฐ์มีความประสงค์จะขอสอนชดเชยประจำ ${academicSemester} เนื่องจากวัน เวลาสอน ตรงกับวันหยุด ซึ่งเป็น`, 20, 89);
    let longText = `${resultText} ทั้งนี้เพื่อให้การเรียนการสอนครบตามเนื้อหาของหลักสูตรที่กำหนด และสามารถนำการสอนชดเชยครั้งนี้ มาคำนวณภาระงานสอนเพื่อขอเบิกค่าสอนพิเศษได้ตามเกณฑ์ ดังนั้นจึงขอสอนชดเชยในวัน เวลา และวิชา รายละเอียดดังต่อไปนี้`

    const splitText = pdf.splitTextToSize(longText, maxWidth);
    let startY = 150;
    const lineHeight = 6
    splitText.forEach((line, index) => {
      pdf.text(line, 19, 95 + index * 6,{align:'left'}); // Adjust 6 for line height
      startY = 95 + index * 6; // Update startY based on the current line
    });

    // pdf.text('ด้วยหลักสูตรวิศวกรรมหุ่นยนต์และปัญญาประดิษฐ์ (หลักสูตรนานาชาติ) และหลักสูตรวิชาโทปัญญาประดิษฐ์',32,119);
    // pdf.text(`มีความประสงค์จะขอสอนชดเชยประจำ ${academicSemester} เนื่องจากวัน เวลาสอน ตรงกับวันหยุด ซึ่งเป็น`, 20, 125);
    // // Split the long text into array of strings that fit within maxWidth
    // // Add each line of the split text to the PDF
    // splitText.forEach((line, index) => {
    //     pdf.text(line, 20, 131 + index * 6); // Adjust 6 for line height
    //     countLine = countLine+1;
    // });
    // pdf.text(resultText,20,131 + countLine * 6)
    // pdf.text('ทั้งนี้ เพื่อให้การเรียนการสอนครบตามเนื้อหาของหลักสูตรที่กำหนด และสามารถนำการสอนชดเชยครั้งนี้มาคำนวณภาระงานสอน',20,131 + countLine * 6);
    // pdf.text('เพื่อขอเบิกค่าสอนพิเศษได้ตามเกณฑ์ ดังนั้นจึงขอสอนชดเชยในวัน เวลา และวิชา รายละเอียดดังต่อไปนี้',20,131 + countLine * 6);


    pdf.addFileToVFS("Sarabun-Regular.ttf", fontbold);//Bold font
    pdf.addFont('Sarabun-Regular.ttf', 'Sarabun-Regular', 'normal');
    pdf.setFont('Sarabun-Regular');

    pdf.setFontSize(20);
    pdf.text('บันทึกข้อความ', 90.5, 27);
    //
    pdf.setFontSize(14);
    //
    pdf.text('หน่วยงาน',20,37); 
    pdf.text('ที่',20,47);
    pdf.text('วันที่',112,47)
    //
    pdf.text('เรื่อง',20,57);
    
    
    let content1= {
      startY: startY + lineHeight,
      head: [[`ตารางการสอนชดเชย ปริญญาตรี เดือน${selectedMonth} ${academicHead}`]],
      styles:{font:'Sarabun-Regular',fillColor:[255,255,255],textColor:[0,0,0],lineWidth: 0.1, lineColor: [0, 0, 0]},fontSize:12,
      headStyles: { valign: 'middle', halign: 'center' }
    }

    const lineHeightContent1 = 8; // Adjust based on the line height of content1

    let content2 = {
      startY: content1.startY + lineHeightContent1, // Add the height of content1's single line
      head: [['ภาควิชาวิศวกรรมหุ่นยนต์และปัญญาประดิษฐ์ และหลักสูตรวิชาโทปัญญาประดิษฐ์ คณะวิศวกรรมศาสตร์']],
      styles: { font: 'Sarabun-Regular', fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.1, lineColor: [0, 0, 0] },
      fontSize: 12,
     headStyles: { valign: 'middle', halign: 'center' }
    };
    const lineHeightContent2 = 8;
    const content3 = {
      startY: content2.startY + lineHeightContent2,
      head: [
        [
          { content: 'รหัสวิชา', styles: { halign: 'center', font:'Sarabun-Regular' } },
          { content: 'ชื่อวิชา', styles: { halign: 'center', font:'Sarabun-Regular' }  },
          { content: 'ท/ป', styles: { valign:'middle',halign: 'center', font:'Sarabun-Regular'  } },
          { content: 'ชั้นปีกลุ่ม', styles: { halign: 'center', font:'Sarabun-Regular'  } },
          { colSpan: 2, content: ['วัน/เวลาสอน'], styles: { halign: 'center', font:'Sarabun-Regular'  } },
          { colSpan: 2, content: ['ตารางสอนชดเชย'], styles: { halign: 'center', font:'Sarabun-Regular'  } },
          { content: 'วันหยุด', styles: { halign: 'center', font:'Sarabun-Regular'  } },
          { content: 'อาจารย์ผู้สอน', styles: { halign: 'center', font:'Sarabun-Regular'  } },
          { content: 'หมายเหตุ', styles: { halign: 'center' , font:'Sarabun-Regular' } },
        ],
        ['', '', '', '',{content: 'วัน/เดือน/ปี',styles:{halign:'center', font:'Sarabun-Regular' }}, {content:'เวลา',styles:{halign:'center', font:'Sarabun-Regular' }}, {content: 'วัน/เดือน/ปี',styles:{halign:'center', font:'Sarabun-Regular' }}, {content:'เวลา',styles:{halign:'center', font:'Sarabun-Regular' }}, ''],
      ],
      body: filteredMakeup.map(item => [
        item.subjectCode,
        item.subjectName,
        item.type,
        `${item.yearOfstudy}/${item.section}`,
        { content: formatDate(item.date), styles: { font: 'THSarabun-normal', halign: 'center' } },
        `${formatTime(item.startTime)}-${formatTime(item.endTime)}`,
        { content: formatDate(item.makeupDate), styles: { font: 'THSarabun-normal', halign: 'center' } },
        `${findMinTimes(item.makeupTimeSlot)}-${findMaxTimes(item.makeupTimeSlot)}`,
        item.conflictWith.name,
        (() => {
          const professors = [];
          let professorIndex = 1;
          while (item[`professor${professorIndex}`] !== null && item[`professor${professorIndex}`] !== undefined) {
              professors.push(item[`professor${professorIndex}`]);
              professorIndex++;
          }
          const content = professors.filter(Boolean).join(', ');
          return content ? { content, styles: { font: 'THSarabun-normal' } } : { content: '', styles: { font: 'THSarabun-normal' } };
      })(),
      item.remark,
    ]),
      
      styles: {
        font: 'THSarabun-normal',
        fontSize: 12,
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
      },
      headStyles: {
        font: 'THSarabun-normal',
        fontSize:10
      },
      bodyStyles: {
        font: 'THSarabun-normal',
        fontSize:8
      },
      didDrawCell: data => {
        if (data.row.index === 0 && data.column.index === 4) {
          data.cell.styles.fontSize = 12;
        }
      },
    };

    pdf.autoTable(content1);
    pdf.autoTable(content2);
    pdf.autoTable(content3);
    pdf.addFileToVFS('THSarabun-normal.ttf', fontTH);
    pdf.addFont('THSarabun-normal.ttf', 'THSarabun-normal', 'normal');
    pdf.setFont('THSarabun-normal');
    
    const tableHeight = pdf.previousAutoTable.finalY;

// Position the text after the table
const textYPosition = tableHeight + 10;

// Check if the text exceeds the page height
if (textYPosition < pdf.internal.pageSize.height - 20) { // Adjust the threshold as needed
    // If the text does not exceed the page height, place it as usual
    pdf.text('จึงเรียนมาเพื่อโปรดพิจารณา', 86, textYPosition);
    pdf.text('ดร.ภูมิ คงห้วยรอบ', 90.5, textYPosition + 20);
    pdf.text('หัวหน้าภาควิชาวิศวกรรมหุ่นยนต์และปัญญาประดิษฐ์', 69, textYPosition + 27);
} else {
    // If the text exceeds the page height, start a new page and place the text at the top
    pdf.addPage();
    pdf.text('จึงเรียนมาเพื่อโปรดพิจารณา', 86, 20);
    pdf.text('ดร.ภูมิ คงห้วยรอบ', 90.5, 40);
    pdf.text('หัวหน้าภาควิชาวิศวกรรมหุ่นยนต์และปัญญาประดิษฐ์', 69, 47);
}




    } else if (selectedDegree === 'ปริญญาโทและปริญญาเอก') {
      const selectedMonth = translateMonthToThai(
        document.getElementById("startMonth").options[startSelectedMonth].text
      );
      headerText = 'หลักสูตรวิศวกรรมศาสตรมหาบัณฑิตและดุษฎีบัณฑิต สาขาวิชาวิศวกรรมหุ่นยนต์และระบบอัจฉริยะเชิงคำนวณ (หลักสูตรสหวิทยาการ)';
      pdf.addImage(img,'JPEG',18,15,18,18) //x,y,w,h image
      //font
      pdf.addFileToVFS('Calibri.ttf', fonteng);
    pdf.addFont('Calibri.ttf', 'Calibri', 'normal');
    pdf.setFont('Calibri');

    pdf.addFileToVFS('THSarabun-normal.ttf', fontTH);
    pdf.addFont('THSarabun-normal.ttf', 'THSarabun-normal', 'normal');
    pdf.setFont('THSarabun-normal');
    pdf.setFontSize(14);
    pdf.text('คณะวิศวกรรมศาสตร์ ภาควิชาวิศวกรรมหุ่นยนต์และปัญญาประดิษฐ์',40,37);
    pdf.text('อว ๗๐๐๒.๑๓/',30,47);
    const thaiDate = { day: 'numeric', month: 'long', year: 'numeric', weekday: 'long' };
    //pdf.text(` ${currentDatePDF.toLocaleDateString('th-TH', thaiDate)}`, 93, 83);
    pdf.text('ขออนุมัติสอนชดเชยหลักสูตรวิศวกรรมศาสตรมหาบัณฑิตและดุษฎีบัณฑิต สาขาวิชาวิศวกรรมหุ่นยนต์และระบบอัจฉริยะเชิงคำนวณ ',30,57);
    const academicHead = `ภาคเรียนที่ ${semester} ปีการศึกษา ${academicYear}`;
    const academicSemester = `ภาคเรียนที่ ${semester} ปีการศึกษา ${academicYear}`;
    pdf.text(`(หลักสูตรสหวิทยาการ) ประจำเดือน${selectedMonth} ${academicSemester}`, 30,64);

    let resultText = '';  // Declare the variable outside the loop

     const filteredMakeup = makeupClassData.filter(item => {
      const itemMonth = new Date(item.date);
      const startDate = new Date(startMonth);
      const endDate = new Date(endMonth)
      return itemMonth >= startDate && itemMonth <= endDate && item.yearOfstudy === 'Graduate';
  });
  

  let isFirstTimeResultText = true;
  filteredMakeup.forEach((makeupItem, index) => {
  const conflictWith = makeupItem.conflictWith;

  if (conflictWith) {
    let conflictNames = Array.isArray(conflictWith.name) ? conflictWith.name : [conflictWith.name];

    // Filter out duplicate conflict names
    conflictNames = [...new Set(conflictNames)];

    // Filter makeupClassData to remove duplicates based on date and conflict names
    const uniqueMakeupData = [];
    const seenDates = new Set(); // Track dates to detect duplicates
    makeupClassData.forEach(item => {
        const itemDate = new Date(item.date);
        if (!seenDates.has(itemDate.toISOString())) {
            seenDates.add(itemDate.toISOString());
            uniqueMakeupData.push(item);
        }
    });

    const filteredMakeup = uniqueMakeupData.filter(item => {
        const itemMonth = new Date(item.date);
        const startDate = new Date(startMonth);
        const endDate = new Date(endMonth);
        return itemMonth >= startDate && itemMonth <= endDate;
    });

    const conflictText = conflictNames.join(', ');

    // Append the conflictText to resultText
    if (isFirstTimeResultText === true) {
        if (filteredMakeup.length === 1) {
            resultText += `วัน ${conflictText}`;
            isFirstTimeResultText = false;
        } else {
            if (index === 0) {
                resultText += `${conflictText}`;
            } else if (index === filteredMakeup.length - 1) {
                resultText += ` และ ${conflictText}`;
                isFirstTimeResultText = false;
            } else {
                resultText += `, ${conflictText}`;
            }
        }
    }
}
  });
  
    
  const maxWidth = 170;
  pdf.text('เรียน', 20, 74);
  pdf.text('คณบดีคณะวิศวกรรมศาสตร์', 30, 74);
  pdf.text('ด้วยหลักสูตรวิศวกรรมศาสตรมหาบัณฑิตและดุษฎีบัณฑิต สาขาวิชาวิศวกรรมหุ่นยนต์และระบบอัจฉริยะเชิงคำนวณ',32,83);
  pdf.text(`(หลักสูตรสหวิทยาการ) ภาควิชาวิศวกรรมหุ่นยนต์และปัญญาประดิษฐ์ มีความประสงค์จะขอสอนชดเชยประจำ ${academicSemester} `, 20, 89);
  let longText = `เนื่องจากวัน เวลาสอน ตรงกับวันหยุด ซึ่งเป็น ${resultText}  ทั้งนี้เพื่อให้การเรียนการสอนครบตามเนื้อหาของหลักสูตรที่กำหนด และสามารถนำการสอนชดเชยครั้งนี้ มาคำนวณภาระงานสอนเพื่อขอเบิกค่าสอนพิเศษได้ตามเกณฑ์ ดังนั้นจึงขอสอนชดเชยในวัน เวลา และวิชา รายละเอียดดังต่อไปนี้`

  const splitText = pdf.splitTextToSize(longText, maxWidth);
  let startY = 150;
    const lineHeight = 6
    splitText.forEach((line, index) => {
      pdf.text(line, 19, 95 + index * 6,{align:'left'}); // Adjust 6 for line height
      startY = 95 + index * 6; // Update startY based on the current line
    });


    pdf.addFileToVFS("Sarabun-Regular.ttf", fontbold);//Bold font
    pdf.addFont('Sarabun-Regular.ttf', 'Sarabun-Regular', 'normal');
    pdf.setFont('Sarabun-Regular');

    pdf.setFontSize(20);
    pdf.text('บันทึกข้อความ', 90.5, 27);
    //
    pdf.setFontSize(14);
    //
    pdf.text('หน่วยงาน',20,37); 
    pdf.text('ที่',20,47);
    pdf.text('วันที่',112,47)
    //
    pdf.text('เรื่อง',20,57);
    


    let content1= {
      startY: startY + lineHeight,
      head: [[`ตารางการสอนชดเชย ปริญญาโทและปริญญาเอก เดือน${selectedMonth} ${academicHead}`]],
      styles:{font:'Sarabun-Regular',fillColor:[255,255,255],textColor:[0,0,0],lineWidth: 0.1, lineColor: [0, 0, 0]},fontSize:12,
      headStyles: { valign: 'middle', halign: 'center' }
    }
    const lineHeightContent1 = 8;
    let content2= {
      startY:content1.startY + lineHeightContent1,
      head: [['หลักสูตรวิศวกรรมศาสตรมหาบัณฑิตและดุษฎีบัณฑิต สาขาวิชาวิศวกรรมหุ่นยนต์และระบบอัจฉริยะเชิงคำนวณ (หลักสูตรสหวิทยาการ)']],
      styles:{font:'Sarabun-Regular',fillColor:[255,255,255],textColor:[0,0,0],lineWidth: 0.1, lineColor: [0, 0, 0]},fontSize:12,
      headStyles: { valign: 'middle', halign: 'center' }
    }
    const lineHeightContent2 = 8;
    const content3 = {
      startY: content2.startY + lineHeightContent2,
      head: [
        [
          { content: 'รหัสวิชา', styles: { halign: 'center', font:'Sarabun-Regular' } },
          { content: 'ชื่อวิชา', styles: { halign: 'center', font:'Sarabun-Regular' } },
          { content: 'ท/ป', styles: { valign:'middle',halign: 'center', font:'Sarabun-Regular' } },
          { content: 'ชั้นปีกลุ่ม', styles: { halign: 'center', font:'Sarabun-Regular' } },
          { colSpan: 2, content: ['วัน/เวลาสอน'], styles: { halign: 'center', font:'Sarabun-Regular' } },
          { colSpan: 2, content: ['ตารางสอนชดเชย'], styles: { halign: 'center', font:'Sarabun-Regular' } },
          { content: 'วันหยุด', styles: { halign: 'center', font:'Sarabun-Regular' } },
          { content: 'อาจารย์ผู้สอน', styles: { halign: 'center', font:'Sarabun-Regular' } },
          { content: 'หมายเหตุ', styles: { halign: 'center', font:'Sarabun-Regular' } },
        ],
        ['', '', '', '',{content: 'วัน/เดือน/ปี',styles:{halign:'center', font:'Sarabun-Regular'}}, {content:'เวลา',styles:{halign:'center', font:'Sarabun-Regular'}}, {content: 'วัน/เดือน/ปี',styles:{halign:'center', font:'Sarabun-Regular'}}, {content:'เวลา',styles:{halign:'center', font:'Sarabun-Regular'}}, ''],
      ],
      body: filteredMakeup.map(item => [
        item.subjectCode,
        item.subjectName,
        item.type,
        `${item.yearOfstudy}/${item.section}`,
        {
          content: formatDate(item.date),
          styles: { font: 'THSarabun-normal' }, 
        },
        `${formatTime(item.startTime)}-${formatTime(item.endTime)}`,
        {content: formatDate(item.makeupDate),
          styles: {
            font: 'THSarabun-normal',
          },
          useNumberFormat: false, // เซ็ทให้เลขแสดงเป็นเลขอารบิก
        },
        `${findMinTimes(item.makeupTimeSlot)}-${findMaxTimes(item.makeupTimeSlot)}`,
        {content: item.conflictWith.name,
          styles: { font: 'THSarabun-normal' },
        },
        (() => {
          const professors = [];
          let professorIndex = 1;
          while (item[`professor${professorIndex}`] !== null && item[`professor${professorIndex}`] !== undefined) {
              professors.push(item[`professor${professorIndex}`]);
              professorIndex++;
          }
          const content = professors.filter(Boolean).join(', ');
          return content ? { content, styles: { font: 'THSarabun-normal' } } : { content: '', styles: { font: 'THSarabun-normal' } };
      })(),
      item.remark,
      ]),
      styles: {
        font: 'THSarabun-normal',
        fontSize: 12,
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
      },
      headStyles: {
        font: 'THSarabun-normal',
        fontSize:10
      },
      bodyStyles: {
        font: 'THSarabun-normal',
        fontSize:8
      },
      didDrawCell: data => {
        if (data.row.index === 0 && data.column.index === 4) {
          data.cell.styles.fontSize = 12;
        }
      },
    };

    
    pdf.autoTable(content1);
    pdf.autoTable(content2);
    pdf.autoTable(content3);
    pdf.addFileToVFS('THSarabun-normal.ttf', fontTH);
    pdf.addFont('THSarabun-normal.ttf', 'THSarabun-normal', 'normal');
    pdf.setFont('THSarabun-normal')

    const tableHeight = pdf.previousAutoTable.finalY;

// Position the text after the table
const textYPosition = tableHeight + 10;

// Check if the text exceeds the page height
if (textYPosition < pdf.internal.pageSize.height - 20) { // Adjust the threshold as needed
    // If the text does not exceed the page height, place it as usual
    pdf.text('จึงเรียนมาเพื่อโปรดพิจารณา', 86, textYPosition);
    pdf.text('ดร.ภูมิ คงห้วยรอบ', 90.5, textYPosition + 20);
    pdf.text('หัวหน้าภาควิชาวิศวกรรมหุ่นยนต์และปัญญาประดิษฐ์', 69, textYPosition + 27);
} else {
    // If the text exceeds the page height, start a new page and place the text at the top
    pdf.addPage();
    pdf.text('จึงเรียนมาเพื่อโปรดพิจารณา', 86, 20);
    pdf.text('ดร.ภูมิ คงห้วยรอบ', 90.5, 40);
    pdf.text('หัวหน้าภาควิชาวิศวกรรมหุ่นยนต์และปัญญาประดิษฐ์', 69, 47);
}
    
      
    } else {
      console.error('')
    }

    const pdfDataUri = pdf.output('datauristring');

    // Open the preview window
    const previewWindow = window.open();

    if (previewWindow) {
      previewWindow.document.write(`<iframe width="100%" height="100%" src="${pdfDataUri}"></iframe>`);
    } else {
      console.error('Unable to open the preview window. Please check your pop-up blocker settings.');
    }
  };
  
  

  const exportToExcel = () => {
    let headerText1;
    let headerText2;
    let filteredMakeup;
    const academicHead = `ภาคเรียนที่ ${semester} ปีการศึกษา ${academicYear}`;
    const selectedMonth = translateMonthToThai(
      document.getElementById("startMonth").options[startSelectedMonth].text
    );
    if (selectedDegree === 'ปริญญาตรี') {
      headerText1 = `ตารางการสอนชดเชย ปริญญาตรี เดือน${selectedMonth} ${academicHead}`;
      headerText2 = 'หลักสูตรวิศวกรรมหุ่นยนต์และปัญญาประดิษฐ์ (หลักสูตรนานาชาติ) และหลักสูตรวิชาโทปัญญาประดิษฐ์';
      filteredMakeup = makeupClassData.filter(item => {
        const itemMonth = new Date(item.makeupDate);
        const startDate = new Date(startMonth);
        const endDate = new Date(endMonth)
        return itemMonth >= startDate && itemMonth <= endDate && item.yearOfstudy !== 'Graduate';
    });
    } else if (selectedDegree === 'ปริญญาโทและปริญญาเอก') {
      headerText1 = `ตารางการสอนชดเชย ปริญญาโทและปริญญาเอก เดือน${selectedMonth} ${academicHead}`;
      headerText2 = 'หลักสูตรวิศวกรรมศาสตรมหาบัณฑิตและดุษฎีบัณฑิต สาขาวิชาวิศวกรรมหุ่นยนต์และระบบอัจฉริยะเชิงคำนวณ (หลักสูตรสหวิทยาการ)';
      filteredMakeup = makeupClassData.filter(item => {
        const itemMonth = new Date(item.makeupDate);
        const startDate = new Date(startMonth);
        const endDate = new Date(endMonth)
        return itemMonth >= startDate && itemMonth <= endDate && item.yearOfstudy === 'Graduate';
    });
    }
    const worksheet = XLSX.utils.aoa_to_sheet([
      // Row 1

      [headerText1],
      // Row 2
      [headerText2],
      // Row 3 to Row 5 (Merged)
      ['รหัสวิชา', 'ชื่อวิชา', 'ท/ป', 'ชั้นปี/กลุ่ม', 'วัน/เวลาสอน', '', '', 'ตารางสอนชดเชย', '', '', 'วันหยุด', 'อาจารย์ผู้สอน', 'หมายเหตุ'],
      [''],
      ['']
    ]);
  
    worksheet['!merges'] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 10 } },
      { s: { r: 2, c: 0 }, e: { r: 4, c: 0 } },
      { s: { r: 2, c: 1 }, e: { r: 4, c: 1 } },
      { s: { r: 2, c: 2 }, e: { r: 4, c: 2 } },
      { s: { r: 2, c: 3 }, e: { r: 4, c: 3 } },
      { s: { r: 2, c: 4 }, e: { r: 2, c: 5 } },
      { s: { r: 3, c: 4 }, e: { r: 4, c: 4 } },
      { s: { r: 3, c: 5 }, e: { r: 4, c: 5 } },
      { s: { r: 2, c: 6 }, e: { r: 2, c: 7 } },
      { s: { r: 3, c: 6 }, e: { r: 4, c: 6 } },
      { s: { r: 3, c: 7 }, e: { r: 4, c: 7 } },
      { s: { r: 2, c: 8 }, e: { r: 4, c: 8 } },
      { s: { r: 2, c: 9 }, e: { r: 4, c: 9 } },
      { s: { r: 2, c: 10 }, e: { r: 4, c: 10 } },
    ];
  
    worksheet['A3'] = { v: 'รหัสวิชา', t: 's' };
    worksheet['B3'] = { v: 'ชื่อวิชา', t: 's' };
    worksheet['C3'] = { v: 'ท/ป', t: 's' };
    worksheet['D3'] = { v: 'ชั้นปี/กลุ่ม', t: 's' };
    worksheet['E3'] = { v: 'วัน/เวลาสอน', t: 's' };
    worksheet['E4'] = { v: 'วัน/เดือน/ปี', t: 's' };
    worksheet['E5'] = { v: 'วัน/เดือน/ปี', t: 's' };
    worksheet['F3'] = { v: 'เวลา', t: 's' };
    worksheet['F4'] = { v: 'เวลา', t: 's' };
    worksheet['F5'] = { v: 'เวลา', t: 's' };
    worksheet['G3'] = { v: 'ตารางสอนชดเชย', t: 's' };
    worksheet['G4'] = { v: 'วัน/เดือน/ปี', t: 's' };
    worksheet['G5'] = { v: 'วัน/เดือน/ปี', t: 's' };
    worksheet['H3'] = { v: 'เวลา', t: 's' };
    worksheet['H4'] = { v: 'เวลา', t: 's' };
    worksheet['H5'] = { v: 'เวลา', t: 's' };
    worksheet['I3'] = { v: 'วันหยุด', t: 's' };
    worksheet['J3'] = { v: 'อาจารย์ผู้สอน', t: 's' };
    worksheet['K3'] = { v: 'หมายเหตุ', t: 's' };
    worksheet['L3'] = { v: '', t: 's' };
    worksheet['M3'] = { v: '', t: 's' };
    
     filteredMakeup.forEach((makeupClassItem, index) => {
      XLSX.utils.sheet_add_aoa(worksheet, [[makeupClassItem.subjectCode]], { origin: { r: 5 + index, c: 0 } });
      XLSX.utils.sheet_add_aoa(worksheet, [[makeupClassItem.subjectName]], { origin: { r: 5 + index, c: 1 } });
      XLSX.utils.sheet_add_aoa(worksheet, [[makeupClassItem.type]], { origin: { r: 5 + index, c: 2 } });
      XLSX.utils.sheet_add_aoa(worksheet, [[makeupClassItem.yearOfstudy + '/' + makeupClassItem.section]], { origin: { r: 5 + index, c: 3 } });
      XLSX.utils.sheet_add_aoa(worksheet, [[formatDate(makeupClassItem.date)]], { origin: { r: 5 + index, c: 4 } });
      XLSX.utils.sheet_add_aoa(worksheet, [[formatTime(makeupClassItem.startTime) + '-' + formatTime(makeupClassItem.endTime)]], { origin: { r: 5 + index, c: 5 } });
      XLSX.utils.sheet_add_aoa(worksheet, [[formatDate(makeupClassItem.makeupDate)]], { origin: { r: 5 + index, c: 6 } });
      const { min, max } = findMinMaxTimes(makeupClassItem.makeupTimeSlot);
      XLSX.utils.sheet_add_aoa(worksheet, [[min + '-' + max]], { origin: { r: 5 + index, c: 7 } });
      XLSX.utils.sheet_add_aoa(worksheet, [[makeupClassItem.conflictWith.name]], { origin: { r: 5 + index, c: 8 } });
      const professors = [];
      let professorIndex = 1;
      while (makeupClassItem[`professor${professorIndex}`] !== null && makeupClassItem[`professor${professorIndex}`] !== undefined) {
          professors.push(makeupClassItem[`professor${professorIndex}`]);
          professorIndex++;
      }
      const professorsString = professors.filter(Boolean).join();
      XLSX.utils.sheet_add_aoa(worksheet, [[professorsString]], { origin: { r: 5 + index, c: 9 } });
    });

    const columnWidths = [
      { wch: 10 },
      { wch: 40 },
      { wch: 5 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 30 },
      { wch: 40 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 }
  ];

  worksheet['!cols'] = columnWidths;
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'makeup_classes.xlsx');
  };

  const handleDegreeChange = (e) => {
    const selectedDegree = e.target.value;
    setSelectedDegree(selectedDegree);

    if (selectedDegree === 'ปริญญาตรี') {
      setFilteredMakeupData(makeupClassData.filter(item => item.yearOfstudy !== 'Graduate'));
    } else if (selectedDegree === 'ปริญญาโทและปริญญาเอก') {
      setFilteredMakeupData(makeupClassData.filter(item => item.yearOfstudy === 'Graduate'));
    }
    const subjectSectionCounts = {};
filteredMakeupData.forEach(item => {
  const key = `${item.subjectName}-${item.section}`;
  subjectSectionCounts[key] = (subjectSectionCounts[key] || 0) + 1;
});
};

  const handleOpenAcademicModal = () => {
    setIsAcademicOpen(true);
  };

  const handleCloseAcademicModal = () => {
    setIsAcademicOpen(false);
  };
  
  const filterAcademic = (selectedYear, selectedSemester) => {
    const filteredClassData = classData.filter(item => item.academicYear === selectedYear && item.semester === selectedSemester);
    const filteredMakeupClassData = makeupClassData.filter(item => item.academicYear === selectedYear && item.semester === selectedSemester);
    const filteredFilterMakeupClassData = makeupClassData.filter(item => item.academicYear === selectedYear && item.semester === selectedSemester);
    setClassData(filteredClassData);
    setMakeupData(filteredMakeupClassData);
    setFilteredMakeupData(filteredFilterMakeupClassData);
    setAcademicYear(selectedYear);
    setSemester(selectedSemester);
    console.log('year', selectedYear);
    console.log('semester', selectedSemester);    
    console.log(filteredMakeupClassData);
    handleDegreeChange({ target: { value: 'ปริญญาตรี' } });
  };

    return (
      <div className="bg">
      <Navbar />
      <div className="calendar-container">
        <div className="calendar">
        <button className="filter" onClick={() => setIsFilterOpen(true)}>
             <FontAwesomeIcon icon={faFilter} style={{ fontSize: '12px' }} />
              </button>
           
            <div className="month-navigation">
              <button className="button-prev" onClick={handlePrevMonth}>&lt;</button>
              <h2 className="month-title">{getMonthName(currentDate.getMonth())} {currentDate.getFullYear()}</h2>
              <button className="button-next" onClick={handleNextMonth}>&gt;</button>
            </div>
            
            <FilterModal
              isOpen={isFilterOpen}
              onClose={() => setIsFilterOpen(false)}
            />
            <ConfigModal
              isOpen={isConfigOpen}
              onClose={() => setIsConfigOpen(false)}
              selectedMakeupClass={selectedMakeupClass}
              onUpdate={handleUpdateFromConfig}
              hasConflict={hasConflict}
            />
            <AcademicModal
              isOpen={isAcademicOpen}
              onClose={handleCloseAcademicModal}
              onSelection={filterAcademic}
            />
            <div className='colorbox-container'>
     <div className='colorbox'>
    <span className='small-box' style={{ backgroundColor: '#FF5733' }}></span>
    <span className='box-content'>Makeup</span>
    <span className='small-box' style={{ backgroundColor: '#27ae60' }}></span>
    <span className='box-content'>Holiday</span>
    <span className='small-box' style={{ backgroundColor: '#e74c3c' }}></span>
    <span className='box-content'>Meeting</span>
    </div>
    </div>
          <table className="calendar-table">
            <thead>
              <tr>
                <th>Sun</th>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
              </tr>
            </thead>
            <tbody>{renderCalendar(currentDate.getMonth())}</tbody>
          </table>
          
        </div>
        
        <div className="info" style={{ maxHeight: '800px', overflowY: 'auto' }}>
          
        <div className='box-container'>

      <div style={{  alignItems: 'center' }}>
  
      <div className='top-container'><div>
      <button className = "export-button"onClick={handleShowPhoto}>Manual</button>
      {/* Use conditional rendering to display the modal */}
      {showPhoto && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleClosePhoto}>&times;</span>
            <img src={manual} alt="Your Photo" />
          </div>
        </div>
      )}
      </div>
      
  <label className='labelcontainer'>
        Select Degree Type :
        <DegreeDropdown selectedValue={selectedDegree} onChange={handleDegreeChange}  />
      </label>
      
  <div className="export-box">
  <button className='export-button' onClick={() => {
        setSelectedDegree((prevDegree) => {
          generatePdf();
          return prevDegree; // Return the previous degree value to update the state
        });
      }}>
         PDF
      </button>
    <button className="export-button" onClick={exportToExcel}>EXCEL</button>
  </div>
</div>
<div class="month-picker">
      <div class="month-picker-section">
        <label for="startMonth">Start Month</label>
        <select id="startMonth" class="month-picker-dropdown" onChange={(e) => {
            const selectedMonth = e.target.value;
            setStartSelectedMonth(e.target.value);
            const selectedDate = new Date(startSelectedYear, selectedMonth, 1); 
            selectedDate.setDate(selectedDate.getDate() + 1);
            setStartMonth(selectedDate.toISOString());
        }} value={startSelectedMonth}>
        <option value="0">January</option>
        <option value="1">February</option>
        <option value="2">March</option>
        <option value="3">April</option>
        <option value="4">May</option>
        <option value="5">June</option>
        <option value="6">July</option>
        <option value="7">August</option>
        <option value="8">September</option>
        <option value="9">October</option>
        <option value="10">November</option>
        <option value="11">December</option>
        </select>
        <select
        id="startYear"
        className="year-picker-dropdown"
        onChange={(e) => {
          const selectedYear = parseInt(e.target.value);
          setStartSelectedYear(selectedYear);
          const selectedDate = new Date(selectedYear, startSelectedMonth, 1);
          selectedDate.setDate(selectedDate.getDate() + 1);
          setStartMonth(selectedDate.toISOString());
        }}
        value={startSelectedYear}
      >
        {Array.from({length: 10}, (_, i) => (
          <option key={startSelectedYear - 5 + i} value={startSelectedYear - 5 + i}>
            {startSelectedYear - 5 + i}
          </option>
        ))}
      </select>
      </div>

      <div class="month-picker-section">
        <label for="endMonth">End Month</label>
        <select id="endMonth" class="month-picker-dropdown" onChange={(e) => {
          const selectedMonth = e.target.value;
            setEndSelectedMonth(e.target.value);
            const selectedDate = new Date(endSelectedYear, selectedMonth, 1); 
            setEndMonth(selectedDate.toISOString());
        }} value={endSelectedMonth}>
        <option value="1">January</option>
        <option value="2">February</option>
        <option value="3">March</option>
        <option value="4">April</option>
        <option value="5">May</option>
        <option value="6">June</option>
        <option value="7">July</option>
        <option value="8">August</option>
        <option value="9">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
        </select>
        <select
          id="endYear"
          className="year-picker-dropdown"
          onChange={(e) => {
            const selectedYear = parseInt(e.target.value);
            setEndSelectedYear(selectedYear);
            const selectedDate = new Date(selectedYear, endSelectedMonth, 1); 
            setEndMonth(selectedDate.toISOString());
          }}
          value={endSelectedYear}
        >
          {Array.from({length: 10}, (_, i) => (
            <option key={endSelectedYear - 5 + i} value={endSelectedYear - 5 + i}>
              {endSelectedYear - 5 + i}
            </option>
          ))}
        </select>
      </div>
      <script>
        
      </script>
    </div>
    <div>
            {loading ? ( // Loading indicator
                <div className="loadicon" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:'400px'}}>
                <img src={loadingGif} alt="Loading..." style={{ width: '100px', height: '100px' }} />
            </div>
            ) : (
                <table className="info-table">
                    <thead>
                        <tr>
                            <th className='Code'>Code</th>
                            <th className='Subject'>Subject</th>
                            <th className='SY'>S/Y</th>
                            <th className='Makeup'>Makeup</th>
                        </tr>
                    </thead>
                    <tbody style={{ height: '100px' }}>
                        {uniqueSubjectSections.map((key, index) => {
                            const lastHyphenIndex = key.lastIndexOf('-'); // Find the last occurrence of "-"
                            const subjectName = key.substring(0, lastHyphenIndex); // Extract subjectName
                            const section = key.substring(lastHyphenIndex + 1); // Extract section
                            const subjectData = filteredMakeupData.find(item => item.subjectName === subjectName && item.section === section);
                            return (
                                <tr key={index}>
                                    <td className="Code2">{subjectData ? subjectData.subjectCode : 'N/A'}</td>
                                    <td className="Subject2">{subjectName}</td>
                                    <td className="SY2">{section}/{subjectData ? subjectData.yearOfstudy : 'N/A'}</td>
                                    <td className="Makeup2">{subjectSectionCounts[key]}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
      </div>
 
</div>
      </div>
  </div>
  
</div>
    );
  }

export default Calendar;