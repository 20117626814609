import React, { useState, useEffect } from 'react';
import fetch_data from '../Component/fetch-data.js';
import './User-access.css';
import Navbar from './Navbar';

function UserList() {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    fetch_data.getUser() 
      .then((response) => response.json())
      .then((userData) => {
        console.log('Userdata : ', userData);
        setUserData(userData);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  }, []);

  const handleRoleChange = (userId, role) => {
    const data = {
      id: userId,
      role: role
    };
    fetch_data.updateUserRole(data)
      .then(() => {
        setUserData((userData) =>
        userData.map((user) =>
          user.id === userId ? { ...user, role: role } : user
        )
      );
      })
      .catch((error)=> {
      console.error(error);
    })
  };

  const handleDeleteUser = (userId) => {
    fetch_data.deleteUser(userId)
      .then(() => {
        setUserData(userData.filter(user => user.id !== userId));
      })
      .catch((error) => {
        console.error('Error deleting user:', error);
      });
  };
  

  return (
    <div>
        <Navbar />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <table className="user-table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Google ID</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody style={{ height: '200px' }}>
          {userData.map((user) => (
            <tr key={user.id}>
              <td>{user.email}</td>
              <td>{user.googleId}</td>
              <td><span
                style={{
                  cursor: 'pointer',
                  border: '2px solid',
                  borderRadius: '30px',
                  padding: '5px 20px',
                  margin: '10px',
                  color: user.role === 'admin' ? '#ffffff' : '#000000',
                  borderColor: user.role === 'admin' ? '#008000' : '#d3d3d3',
                  backgroundColor: user.role === 'admin' ? '#008000' : '#d3d3d3'
                }}
                onClick={() => handleRoleChange(user.id, 'admin')}>
                Admin
              </span>
              <span
                style={{
                  cursor: 'pointer',
                  border: '2px solid',
                  borderRadius: '30px',
                  padding: '5px 20px',
                  margin: '10px',
                  color: user.role === 'co-admin' ? '#ffffff' : '#000000',
                  borderColor: user.role === 'co-admin' ? '#ff8000' : '#d3d3d3',
                  backgroundColor: user.role === 'co-admin' ? '#ff8000' : '#d3d3d3'
                }}
                onClick={() => handleRoleChange(user.id, 'co-admin')}>
                Co-Admin
              </span>
              <span
                style={{
                  cursor: 'pointer',
                  border: '2px solid',
                  borderRadius: '30px',
                  padding: '5px 20px',
                  margin: '10px',
                  color: user.role === 'user' ? '#ffffff' : '#000000',
                  borderColor: user.role === 'user' ? '#0000ff' : '#d3d3d3',
                  backgroundColor: user.role === 'user' ? '#0000ff' : '#d3d3d3'
                }}
                onClick={() => handleRoleChange(user.id, 'user')}>
                User
              </span>
              </td>
              <td style={{border: 'none'}}>
                  <span style={{cursor: 'pointer'}} onClick={() => handleDeleteUser(user.id)}>
                    x
                  </span>
                </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
}

export default UserList;